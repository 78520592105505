import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import sqlTableMapping from "./sqlTableMapping";

function LessonPackageSelection({
  devPort,
  clubId,
  selectedPackages,
  setSelectedPackages,
  tableType,
  multiple_packages,
  setNoReferenceYet,
}) {
  const [lessonPackages, setLessonPackages] = useState();
  useEffect(() => {
    const packageFetch =
      devPort + "lesson_package/" + clubId + "/" + sqlTableMapping[tableType];
    console.log(packageFetch);
    fetch(packageFetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let packageRefs = {};
        let newLessonPricing = [];
        console.log(data);
        if (data) {
          data.map((individualPrice) => {
            if (!packageRefs[individualPrice[2]]) {
              packageRefs[individualPrice[2]] = true;
              newLessonPricing.push({
                label: individualPrice[2],
                value: individualPrice[2],
              });
            }
            return null;
          });
        }
        if (setNoReferenceYet) {
          if (!newLessonPricing || newLessonPricing.length === 0) {
            setNoReferenceYet(true);
          } else {
            setNoReferenceYet(false);
          }
        }
        setLessonPackages(newLessonPricing);
      });
  }, [devPort, clubId, tableType, setNoReferenceYet]);
  console.log(selectedPackages);
  if (!lessonPackages || lessonPackages.length === 0) {
    return;
  }
  return (
    <Autocomplete
      multiple={multiple_packages}
      id="lessonPackage"
      data-testid={"dropwdown-lesson-package-" + (setNoReferenceYet ? "reference" : "regular")}
      options={lessonPackages}
      isOptionEqualToValue={(option, value) => {
        console.log(option);
        console.log(value);
        return option.value === value.value;
      }}
      value={
        selectedPackages
          ? multiple_packages
            ? selectedPackages.map((singlePackage) => {
                return { label: singlePackage, value: singlePackage };
              })
            : selectedPackages
          : []
      }
      //defaultValue={selectedPackages}
      sx={{ width: 325 }}
      renderInput={(params) => (
        <TextField {...params} label={"Select " + tableType + " Packages"} />
      )}
      onChange={(event, newValue) => {
        let newSelectedPackages;
        console.log(event);
        console.log(newValue);
        if (multiple_packages) {
          newSelectedPackages = newValue.map((packagePricing) => {
            return packagePricing.value;
          });
        } else {
          newSelectedPackages = newValue.value;
        }
        console.log(newSelectedPackages);
        setSelectedPackages(newSelectedPackages);
      }}
    />
  );
}

export default LessonPackageSelection;
