import { Button, Card, CardContent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import postProcessing from "./postProcessing";
import DeleteIcon from "@mui/icons-material/Delete";
import VerifiedIcon from "@mui/icons-material/Verified";

function SingleUser({ userData, managerMode, devPort, listMode, refresher }) {
  function moveOffWaitlist(
    userId,
    playerFirstName,
    playerLastName,
    registerClubId
  ) {
    const editPlayer =
      devPort +
      "edit_player/" +
      userId +
      "/" +
      playerFirstName +
      "/" +
      playerLastName +
      "/-2/-2/" +
      registerClubId +
      "/false/0";
    console.log(editPlayer);
    fetch(editPlayer)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("OFF WAITLIST");
        console.log(data);
        refresher();
      });
  }

  return (
    <Card>
      <CardContent>
        <Grid2 container xs={12}>
          <Grid2 xs={6}>{userData.label}</Grid2>
          {managerMode === -1 && listMode === "waitlist" && (
            <Grid2 xs={3}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  console.log("Accept");
                  moveOffWaitlist(
                    userData.value,
                    userData.firstName,
                    userData.lastName,
                    userData.clubId
                  );
                }}
              >
                <VerifiedIcon />{" "}
              </Button>
            </Grid2>
          )}
          {managerMode === -1 && (
            <Grid2 xs={3}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  console.log("DELETE");
                  const fetch_delete =
                    listMode === "stripe_connect_access"
                      ? devPort + "edit_player_connect"
                      : "";
                  const fetch_json =
                    listMode === "stripe_connect_access"
                      ? {
                          club_id: userData.clubId,
                          player_id: userData.value,
                          allowed_stripe_connect: 0,
                        }
                      : {};
                  postProcessing(fetch_delete, fetch_json, () => {
                    console.log("Request has been rejected");
                    refresher();
                  });
                }}
              >
                <DeleteIcon />{" "}
              </Button>
            </Grid2>
          )}
        </Grid2>
      </CardContent>
    </Card>
  );
}

export default SingleUser;
