import { IconButton, Tooltip } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";

export default function PaidLease({ hasPaid }) {
  if (!hasPaid) {
    return "-";
  }
  return (
    <Tooltip title="Player has paid">
      <IconButton>
        <PaidIcon color="success" />
      </IconButton>
    </Tooltip>
  );
}
