import { Fab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function Edit({
    playerInfo,
    setplayerModalEdit,
    setPlayerId,
    setPlayerFirstName,
    setPlayerLastName,
    setPlayerRating,
    setPlayerClubRating,
    setPlayerUSPARating,
    setPlayerIsPro,
    setChukkerNumber,
    setProRequest,
    setPlayerHasSignedWaiver,
    setPlayerModal,
    page
  }) {
    return (
      <Fab
        color="primary"
        size="small"
        aria-label="edit"
        disabled={page==="lessons"}
        onClick={() => {
          setplayerModalEdit(true);
          setPlayerId(playerInfo["player_id"]);
          setPlayerFirstName(playerInfo["first_name"]);
          setPlayerLastName(playerInfo["last_name"]);
          setPlayerRating(playerInfo["uspa_rating"]);
          setPlayerClubRating(playerInfo["club_rating"]);
          setPlayerUSPARating(playerInfo["uspa_rating"]);
          setPlayerIsPro(playerInfo["is_club_pro"] === 1);
          if (playerInfo["count_chukkers_requested"]) {
            setChukkerNumber(playerInfo["count_chukkers_requested"]);
            setProRequest(playerInfo["selected_pro_id"]);
          }
          if (setPlayerHasSignedWaiver) {
            setPlayerHasSignedWaiver(playerInfo["up_to_date_waiver"]);
          }
          setPlayerModal(true);
        }}
      >
        <EditIcon />
      </Fab>
    );
  }