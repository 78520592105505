function deletePlayer(fetch_cmd, customCallback) {
  //fetch(devPort + "/delete_player/" + playerId + "/" + clubId)
  fetch(fetch_cmd)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      customCallback();
    });
}

export default deletePlayer;
