function getStripeConnectSetupCoachesList(
  devPort,
  clubId,
  setStripeConnectUsers,
  proMapping,
  setRecipient,
  recipientId
) {
  const fetch_club_access = devPort + "get_stripe_coaches_club/" + clubId;
  console.log(fetch_club_access);
  fetch(fetch_club_access)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("Stripe Access");
      console.log(data);
      if (!recipientId) {
        setRecipient(null);
      }
      const newData = data.map((info) => {
        const stripeRecipient = {
          label: info[3] ? "You" : "Coach " + proMapping[info[0]],
          value: info[3] ? info[1] : info[0],
          firstName: info[3] ? "You" : "Coach ",
          lastName: info[3] ? info[1] : info[0],
          clubId: clubId,
        };
        if (
          (info[3] && recipientId === -1) ||
          (!info[3] && recipientId === info[0])
        ) {
          setRecipient(stripeRecipient);
        }
        return stripeRecipient;
      });
      if (newData) {
        setStripeConnectUsers(newData);
      } else {
        setStripeConnectUsers([]);
      }
    });
}

export default getStripeConnectSetupCoachesList;
