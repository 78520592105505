import {
  Button,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import StripeConnectCardWrapper from "./StripeConnectCardWrapper";

function StripeConnectCard({ devPort, userName }) {
  const [stripeConnectId, setStripeConnectId] = useState();
  const [reloadStripeConnectId, setReloadStripeConnectId] = useState(false);
  const [loadingStripeConnectId, setLoadingStripeConnectId] = useState(true);
  const [needSetup, setNeedSetup] = useState(false);
  useEffect(() => {
    if (stripeConnectId) {
      setLoadingStripeConnectId(true);
      fetch(devPort + "check_stripe_connect_setup/" + stripeConnectId)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("Account Setup");
          setNeedSetup(!data);
          setLoadingStripeConnectId(false);
        });
    }
  }, [stripeConnectId, devPort]);
  function generate_stripe_account() {
    setLoadingStripeConnectId(true);
    fetch(devPort + "system_register_stripe_connect_id/" + userName)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setReloadStripeConnectId(!reloadStripeConnectId);
        setLoadingStripeConnectId(false);
      });
  }
  useEffect(() => {
    setLoadingStripeConnectId(true);
    //TODO update with stripe customer name
    console.log(devPort + "system_get_stripe_connect_id/" + userName);
    fetch(devPort + "system_get_stripe_connect_id/" + userName)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data && data[0][0] !== null) {
          console.log("Stripe Connect Id " + data);
          setStripeConnectId(data[0][0]);
          //navigate("/selectmembership");
        } else {
          console.log("No Stripe Connect Id");
        }
        setLoadingStripeConnectId(false);
      });
  }, [reloadStripeConnectId, devPort, userName]);

  function createStripeConnectForm() {
    const local_website = window.location.href.split("#")[0].split("/")
    fetch(devPort + "create-stripe-connect-form", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: userName,
        //email: userEmail,
        stripe_connect_id: stripeConnectId,
        website: local_website[0] + "//" + local_website[2], // window.location.href.split("#")[0], //"http://localhost:3000"
      }),
    })
      .then((response) => {
        console.log("ABC");
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log("DEF");
        console.log(data);
        window.location.href = data.url;
      });
  }

  return (
    <StripeConnectCardWrapper>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Stripe Connect Account will allow you to directly charge your customer
        for lessons. Players will pay through Stripe when signing up for a
        lesson and the money will be directly deposited to your account.
      </Typography>
      {loadingStripeConnectId && <LinearProgress />}
      <Stack spacing={2}>
        {!loadingStripeConnectId && stripeConnectId && (
          <TextField
            id="stripe-connect-settings"
            label="Stripe Connect Id"
            variant="filled"
            data-testid="stripe-connect-id"
            //style={{ width: "90%" }}
            value={stripeConnectId}
            disabled={true}
          />
        )}
        {!loadingStripeConnectId && stripeConnectId && needSetup && (
          <div>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              data-testid="missing-stripe-connect-details"
            >
              Your account is missing some information to start charging your
              customers
            </Typography>
            <Button
              variant="contained"
              color="primary"
              data-testid="finish-stripe-connect-setup"
              style={{ width: "100%" }}
              onClick={createStripeConnectForm}
            >
              Finish Stripe Account Setup
            </Button>
          </div>
        )}
        {!loadingStripeConnectId && !stripeConnectId && (
          <Button
            variant="contained"
            color="primary"
            data-testid="generate_stripe_connect_account"
            style={{ width: "100%" }}
            onClick={generate_stripe_account}
          >
            Create Stripe Connect Account
          </Button>
        )}
        {!loadingStripeConnectId && stripeConnectId && (
          <Button
            variant="outlined"
            color="primary"
            data-testid="access_stripe_connect_account"
            style={{ width: "100%" }}
            onClick={() => {
              console.log("Redirecting to Stripe Express")
              window.location.href = "https://connect.stripe.com/express_login"
            }}
          >
            Access My Stripe Connect Account
          </Button>
        )}
      </Stack>
    </StripeConnectCardWrapper>
  );
}

export default StripeConnectCard;
