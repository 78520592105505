import { Box, Button, Modal, Stack, Typography } from "@mui/material";

function DeletionModal({
  deleteModal,
  setDeleteModal,
  deleteString,
  deleteId,
  setDeleteId,
  onDelete,
}) {
  console.log(deleteModal);
  console.log(deleteString);
  console.log(deleteId);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={deleteModal}
      onClose={() => {
        setDeleteModal(false);
      }}
      aria-labelledby={"Delete " + deleteString}
      aria-describedby={"Delete " + deleteString}
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography id="modal-prompt" variant="h6" component="h2">
            Are you sure you want to delete {deleteString} {deleteId}?
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="success" onClick={onDelete}>
              Yes
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setDeleteModal(false);
                setDeleteId("");
              }}
            >
              No
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default DeletionModal;
