import { Button, Card, CardContent, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import Layout from "../components/layout/Layout";
import MainHorses from "./MainHorses.jpeg";
import MainMallet from "./MainMallet.jpeg";
import Grid from "@mui/material/Unstable_Grid2";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { Parallax as ParallaxReactBanner } from "react-parallax";
import "./pages.css";
import BackgroundParallax from "../components/parallax/BackgroundParallax";
import { useNavigate } from "react-router-dom";
import SnackSuccess from "../components/Signups/SnackSuccess";

import CookieConsent from "react-cookie-consent";

function MainPage({
  userName,
  setUserName,
  devPort,
  currentMembership,
  alertMessageSuccess,
  setAlertMessageSuccess,
  setBetaMode,
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  console.log(theme.palette);
  return (
    <Layout
      managerMode={userName ? (currentMembership === "manager" ? -4 : -5) : -3}
      devPort={devPort}
    >
      <SnackSuccess
        alterMessage={alertMessageSuccess}
        setAlertMessage={setAlertMessageSuccess}
        snackMessage="Your message has been sent to the Chukkerbook team!"
      />
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="ChukkerBookCookieConsent"
        style={{ background: theme.palette.primary.main }}
        ButtonComponent={Button}
        buttonStyle={{ background: theme.palette.success.main }}
        overlay={true}
        onOverlayClick={() => console.log("Accept cookies first")}
        expires={150}
      >
        This website uses only strictly necessary cookies to keep the user
        logged in during a session.{" "}
      </CookieConsent>
      <ParallaxProvider>
        <Stack spacing={2}>
          <BackgroundParallax />
          <ParallaxReactBanner
            className="image"
            blur={10}
            bgImage={MainHorses}
            strength={700}
          >
            <Stack spacing={2}>
              {/* <Parallax
                translateX={["-50%", "50%"]}
                startScroll={0}
                endScroll={500}
  >*/}
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Chukkerbook makes it easy for polo club managers to
                        schedule games and lessons and create well-matched club
                        chukkers with no manual matching. Club managers can
                        create a club profile and set the club chukker schedule
                        for the season. Members can sign-up each week, and
                        ChukkerBook will automatically generate chukker
                        match-ups based on skill ratings and number of chukkers
                        requested.
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <img
                        src={MainMallet}
                        alt="Mallets in field"
                        width="100%"
                        onDoubleClick={() => setBetaMode(true)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {/*</Parallax>*/}
              <Parallax
                //translateX={["50%", "-50%"]}
                translateX={["-1000px", "0px"]}
                shouldAlwaysCompleteAnimation
                //speed={-50}
                //startScroll={600}
                //endScroll={750}
                //shouldAlwaysCompleteAnimation
              >
                <Card>
                  <CardContent>
                    <h1 style={{ textAlign: "center" }}>Want to try it out?</h1>
                    <Stack>
                      <Button
                        color="secondary"
                        variant="contained"
                        data-testid="contact-us-button"
                        onClick={() => navigate("/contact")}
                      >
                        Contact Us
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Parallax>
            </Stack>
          </ParallaxReactBanner>
        </Stack>
      </ParallaxProvider>
    </Layout>
  );
}
/*       <Stack spacing={2}>
          <Card>
            <CardMedia
              component="img"
              image={MainHorses}
              alt="Cantering Horses"
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    Chukkerbook makes it easy for polo club managers to schedule
                    games and lessons and create well-matched club chukkers with
                    no manual matching. Club managers can create a club profile
                    and set the club chukker schedule for the season. Members
                    can sign-up each week, and ChukkerBook will automatically
                    generate chukker match-ups based on skill ratings and number
                    of chukkers requested.
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <img src={MainMallet} width="100%" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Parallax speed={4}>
            <img src={MainHorses} />
          </Parallax>
          <Card>
            <CardContent>
              <h1 style={{ textAlign: "center" }}>Want to try it out?</h1>
              <h2 style={{ textAlign: "center" }}>Join our Beta</h2>
              <Stack>
                <Button color="secondary" variant="contained">
                  Contact Us
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Parallax>
    </Layout>
  );
}*/
export default MainPage;
