import {
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import EmailBox from "./EmailBox";
import { useNavigate } from "react-router-dom";

function UserContact({ devPort, setAlertMessage, setAlertMessageSuccess }) {
  let navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState();
  const [inquiry, setInquiry] = useState();
  function sendInfoRequest() {
    if (!loginEmail || !inquiry) {
      window.alert("Set a valid email and message");
    } else {
      const contact_message = {
        email: loginEmail,
        message: inquiry,
      };
      console.log(contact_message);
      const fetch_contact_chukkerbook = devPort + "contact_chukkerbook";
      fetch(fetch_contact_chukkerbook, {
        method: "POST",
        body: JSON.stringify(contact_message),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("REST");
          console.log(data);
          if (data && data["message"] === "Successful Contact") {
            setAlertMessageSuccess(true);
            navigate("/");
          } else {
            setAlertMessage(true);
          }
        });
    }
  }
  return (
    <Grid2 container xs={12}>
      <Grid2 xs={9} xsOffset={1.5}>
        <Paper elevation={24}>
          <Card variant="elevation">
            <CardContent>
              <Stack spacing={2}>
                <h5> Contact Us</h5>
                <EmailBox setLoginEmail={setLoginEmail} />
                <TextField
                  id="contact-inquiry-info"
                  label="Enter your message"
                  data-testid="contact-inquiry-info"
                  value={inquiry}
                  multiline
                  rows={4}
                  onChange={(event) => setInquiry(event.target.value)}
                  //defaultValue="Default Value"
                />
                <Button
                  variant="contained"
                  data-testid="submit-inquiry-info"
                  color="primary"
                  onClick={sendInfoRequest}
                >
                  Contact Us
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Paper>
      </Grid2>
    </Grid2>
  );
}

export default UserContact;
