import {
  Autocomplete,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import getStripeConnectSetupCoachesList from "./getStripeConnectSetupCoaches";
import sqlTableMapping from "./sqlTableMapping";

function PricingBox({
  devPort,
  clubId,
  selectedPrice,
  setSelectedPrice,
  selectedPackages,
  setSelectedPackages,
  recipient,
  setRecipient,
  proMapping,
  tableType,
  recipientId,
}) {
  const [lessonPricing, setLessonPricing] = useState();
  const [lessonPackages, setLessonPackages] = useState();
  const [loadingPricing, setLoadingPricing] = useState(true);
  const [stripeConnectUsers, setStripeConnectUsers] = useState([]);
  useEffect(() => {
    console.log(
      devPort + "lesson_pricing/" + clubId + "/" + sqlTableMapping[tableType]
    );
    fetch(
      devPort + "lesson_pricing/" + clubId + "/" + sqlTableMapping[tableType]
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const newLessonPricing = data.map((individualPrice) => {
          const pricingDetails = {
            label:
              individualPrice[1] +
              " " +
              individualPrice[2] / 100.0 +
              " " +
              individualPrice[3],
            value: individualPrice[4],
          };
          if (selectedPrice === pricingDetails.value) {
            setSelectedPrice(pricingDetails);
          }
          return pricingDetails;
        });
        setLessonPricing(newLessonPricing);
        setLoadingPricing(false);
      });

    if (tableType !== "Lease") {
      const packageFetch =
        devPort + "lesson_package/" + clubId + "/" + sqlTableMapping[tableType];
      console.log(packageFetch);
      fetch(packageFetch)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          let packageRefs = {};
          let newLessonPricing = [];
          if (data) {
            data.map((individualPrice) => {
              if (!packageRefs[individualPrice[2]]) {
                packageRefs[individualPrice[2]] = true;
                newLessonPricing.push({
                  label: individualPrice[2],
                  value: individualPrice[2],
                });
              }
              return null;
            });
          }
          console.log("FETCJER");
          console.log(newLessonPricing);
          setLessonPackages(newLessonPricing);
        });
    }
    getStripeConnectSetupCoachesList(
      devPort,
      clubId,
      setStripeConnectUsers,
      proMapping,
      setRecipient,
      recipientId
    );
    //getStripeConnectAccessList(devPort, clubId, setStripeConnectUsers);
  }, [
    devPort,
    clubId,
    proMapping,
    tableType,
    recipientId,
    selectedPrice,
    setSelectedPrice,
    setRecipient,
  ]);

  /*useEffect(() => {
    if (
      stripeConnectUsers.length > 0 &&
      stripeConnectUsers[stripeConnectUsers.length - 1].label !== "You"
    ) {
      const newData = stripeConnectUsers;
      newData.push({ label: "You", value: -1 });
      setStripeConnectUsers(newData);
    }
  }, [devPort, clubId, stripeConnectUsers]);*/
  console.log(lessonPricing);
  console.log(lessonPackages);
  if (loadingPricing) {
    return <LinearProgress color="secondary" />;
  }
  if (lessonPricing && stripeConnectUsers.length !== 0) {
    return (
      <Stack spacing={2}>
        <Autocomplete
          id="lessonPricing"
          data-testid="modal-lesson-price"
          options={lessonPricing}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={selectedPrice || null}
          sx={{ width: 325 }}
          renderInput={(params) => (
            <TextField {...params} label={tableType + " Price"} />
          )}
          onChange={(event, newValue) => {
            setSelectedPrice(newValue);
          }}
        />
        {lessonPackages && (
          <Autocomplete
            multiple
            id="lessonPackage"
            data-testid="modal-lesson-package"
            options={lessonPackages}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            //value={selectedPackages || null}
            sx={{ width: 325 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Select " + tableType + " Packages"}
              />
            )}
            onChange={(event, newValue) => {
              let newSelectedPackages = newValue.map((packagePricing) => {
                return packagePricing.value;
              });
              setSelectedPackages(newSelectedPackages);
            }}
          />
        )}
        <Autocomplete
          id="lessonAccount"
          data-testid="lesson-account-selection"
          options={stripeConnectUsers}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={recipient || null}
          sx={{ width: 325 }}
          renderInput={(params) => (
            <TextField {...params} label="Recipient Stripe Account" />
          )}
          onChange={(event, newValue) => {
            console.log(newValue);
            setRecipient(newValue);
          }}
        />
      </Stack>
    );
  }
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          <WarningIcon /> No pricing or Stripe Connect account found. You need
          to assign a price in the "Set Lesson Pricing" menu in order to enable
          online payment and you or one of the club coaches need to have a
          Stripe Connect account. Go to the Settings page to set it up.
        </Typography>
      </CardContent>
    </Card>
  );
}

export default PricingBox;
