import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import PricingBox from "../Lessons/PricingBox";
import getProMapping from "./getProMapping";
import dayjs from "dayjs";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LessonPackageSelection from "../Lessons/LessonPackageSelection";

function GameRegistrationModal({
  devPort,
  accessMode,
  registerModal,
  setRegisterModal,
  clubId,
  setIsLoading,
  setAlertMessage,
  setMessageContent,
  filterGames,
  registerDate,
  setRegisterDate,
  registerStartTime,
  setRegisterStartTime,
  registerField,
  setRegisterField,
  registerManagerNotes,
  setRegisterManagerNotes,
  registerGameType,
  setRegisterGameType,
  selectedPrice,
  setSelectedPrice,
  recipient,
  setRecipient,
  numberHorseChukker,
  setNumberHorseChukker,
  modalType,
  gameId,
  setGameDelete,
  setGameToDelete,
  setDeleteModal,
  recipientId,
}) {
  //Pro Mapping
  const [proMapping, setProMapping] = useState();
  const [registerModalPage2, setRegisterModalPage2] = useState(false);
  const [selectedMembershipPackages, setSelectedMembershipPackages] =
    useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    console.log(devPort + "get_horse_chukker_club/" + clubId);
    fetch(devPort + "get_horse_chukker_club/" + clubId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data[0]) {
          setNumberHorseChukker(data[0][0]);
        }
      });
  }, [devPort, clubId, setNumberHorseChukker]);

  useEffect(() => {
    const player_fetcher = devPort + "player_list/" + clubId + "/True";
    getProMapping(
      devPort,
      player_fetcher,
      setProMapping,
      () => {},
      () => {} //setLoadingClubPros
    );
  }, [devPort, clubId]);

  function verifyFirstPageFields() {
    if (!registerDate || !registerStartTime) {
      window.alert("Enter a date and time");
    } else {
      if (!registerField) {
        window.alert("Enter a field name");
      } else {
        setRegisterModal(false);
        setRegisterModalPage2(true);
      }
    }
  }
  function registerGame(registerMode) {
    if (!registerDate || !registerStartTime) {
      window.alert("Enter a date and time");
    } else {
      if (!registerField) {
        window.alert("Enter a field name");
      } else {
        if (
          (!selectedPrice && !selectedMembershipPackages && recipient) ||
          (selectedPrice && !recipient) ||
          (selectedMembershipPackages && selectedMembershipPackages.length !== 0 && !recipient)
        ) {
          window.alert(
            "Select a price and a recipient or unselect both of them"
          );
        } else {
          const utc_game_time = moment(
            registerDate.format("YYYY-MM-DD") +
              " " +
              registerStartTime.format("HH:mm")
          )
            .utc()
            .format("YYYY-MM-DD HH:mm");
          const game_data = {
            club_id: clubId,
            game_date: utc_game_time,
            field_location: registerField,
            game_type: registerGameType,
            manager_notes: registerManagerNotes ? registerManagerNotes : null,
            lease_price: !selectedPrice ? null : selectedPrice.value,
            lease_recipient: !recipient ? null : recipient.value,
            selected_membership_packages: selectedMembershipPackages,
            number_horse_chukker: numberHorseChukker,
            game_id: gameId,
          };
          console.log(game_data);
          const fetch_end_point =
            registerMode === "save" ? "add_game" : "update_game";
          fetch(devPort + fetch_end_point, {
            mode: "cors",
            credentials: "include",
            method: "POST",
            body: JSON.stringify(game_data),
          }).then((data) => {
            setRegisterModal(false);
            setIsLoading(true);
            filterGames();
            setRecipient(null);
            setMessageContent(
              registerMode === "save" ? "added to" : "updated in"
            );
            setAlertMessage(true);
          });
        }
      }
    }
  }

  useEffect(() => {
    console.log(devPort + "get_default_membership_club/" + clubId);
    fetch(devPort + "get_default_membership_club/" + clubId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data[0] && data[0] && data[0][0]) {
          setSelectedMembershipPackages(data[0]);
        }
      });
  }, [devPort, clubId]);

  return (
    <>
      <Modal
        open={registerModalPage2}
        onClose={() => {
          setRegisterModalPage2(false);
        }}
        aria-labelledby="Game Registration 2/2"
        aria-describedby="Create new game 2/2"
      >
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography id="game-date" variant="h6" component="h2">
              {accessMode} New Game
            </Typography>
            <PricingBox
              devPort={devPort}
              clubId={clubId}
              selectedPrice={selectedPrice}
              setSelectedPrice={setSelectedPrice}
              recipient={recipient}
              setRecipient={setRecipient}
              proMapping={proMapping}
              tableType="Lease"
              recipientId={recipientId}
            />
            <TextField
              id="add-chukker-horse-number"
              label="Number of chukker horse to lease"
              data-testid="add-horse-number"
              style={{ width: "100%" }}
              value={numberHorseChukker}
              disabled={!selectedPrice}
              onChange={(input) => {
                const intLesson = parseInt(input.target.value);
                if (!Number.isNaN(intLesson)) {
                  setNumberHorseChukker(intLesson);
                } else {
                  setNumberHorseChukker("");
                }
              }}
            />
            <LessonPackageSelection
              devPort={devPort}
              clubId={clubId}
              selectedPackages={selectedMembershipPackages}
              setSelectedPackages={setSelectedMembershipPackages}
              tableType="Membership"
              multiple_packages={true}
            />
            <Grid2 container xs={12}>
              <Grid2 xs={5.5}>
                {modalType === "save" && (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => registerGame("save")}
                    style={{ width: "100%" }}
                  >
                    Save Game
                  </Button>
                )}
                {modalType === "update" && (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => registerGame("update")}
                    style={{ width: "100%" }}
                  >
                    Update Game
                  </Button>
                )}
              </Grid2>
              <Grid2 xs={5.5} xsOffset={1}>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setRecipient(null);
                    setRegisterModalPage2(false);
                  }}
                >
                  Close
                </Button>
              </Grid2>
            </Grid2>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={registerModal}
        onClose={() => {
          setRegisterModal(false);
        }}
        aria-labelledby="Game Registration"
        aria-describedby="Create new game"
      >
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography id="game-date" variant="h6" component="h2">
              {accessMode} New Game
            </Typography>
            <DatePicker
              value={registerDate ? dayjs(registerDate) : null}
              onChange={(event) => {
                console.log(event);
                setRegisterDate(moment(event["$d"])); // format("YYYY-MM-DD"));
              }}
            />
            <TimePicker
              label="Set Start Time"
              value={registerStartTime}
              onChange={(event) => {
                setRegisterStartTime(moment(event["$d"]));
                console.log(moment(event["$d"]).format("HH:mm"));
              }}
            />
            <TextField
              id="field-name"
              label="Field Name"
              variant="filled"
              value={registerField}
              onChange={(event) => {
                setRegisterField(event.target.value);
              }}
            />
            <TextField
              id="manager-notes"
              label="Details"
              variant="filled"
              value={registerManagerNotes}
              onChange={(event) => {
                setRegisterManagerNotes(event.target.value);
              }}
            />
            <FormLabel id="game-type-group-label">Game Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="game-type-group-label"
              value={registerGameType}
              name="game-type-picker"
              onChange={(event) => {
                setRegisterGameType(event.target.value);
              }}
            >
              <FormControlLabel
                value="OUTDOOR"
                control={<Radio />}
                label="Outdoor"
              />
              <FormControlLabel
                value="ARENA"
                control={<Radio />}
                label="Arena"
              />
            </RadioGroup>

            {modalType === "update" && (
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setGameDelete(gameId);
                  setGameToDelete(gameId);
                  setRegisterModal(false);
                  setDeleteModal(true);
                }}
              >
                <DeleteIcon />
                Delete Game
              </Button>
            )}
            <Grid2 container xs={12}>
              <Grid2 xs={5.5}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={verifyFirstPageFields} //registerLesson}
                >
                  <NavigateNextIcon />
                  Next
                </Button>
              </Grid2>
              <Grid2 xs={5.5} xsOffset={1}>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setRecipient(null);
                    setRegisterModal(false);
                  }}
                >
                  Close
                </Button>
              </Grid2>
            </Grid2>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default GameRegistrationModal;
