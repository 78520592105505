function userLogout(devPort) {
  fetch(devPort + "delete_cookies", {
    credentials: "include",
    mode: "cors",
  }).then((response) => {
    return response.json();
  });
}

export default userLogout;
