import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import EmailBox from "./EmailBox";
import cryptoField from "./cryptoField";
//require("stream-browserify")
//require("crypto-browserify");
//var CryptoJS = require("crypto-js");
//var crypto = require('node:crypto');
//import crypto from "crypto"
//var cryptoNode = require("crypto");
//crypto.createPublicKey

function UserLogin({
  devPort,
  newSignup,
  setUserName,
  setAlertMessage,
  setSnackMessage,
  setAlertMessageSuccess,
  setSnackMessageSuccess,
  signupError,
}) {
  const [loginUserName, setLoginUserName] = useState();
  const [loginPassword, setLoginPassword] = useState();
  const [loginEmail, setLoginEmail] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [displayEmailReset, setDisplayEmailReset] = useState();
  const accountCreationError = "Error creating the account. Please try again";
  //const invalidCredentails = "Invalid username or password";

  //useEffect(() => {
  //  importPublicKeyAndEncrypt().then((response) => console.log(response));
  //}, []);
  //var ciphertext = cryptoNode.publicEncrypt(public_key, "HELLO WORLD");
  //CryptoJS.AES.encrypt("HELLO", public_key).toString();
  //console.log(ciphertext);
  useEffect(() => {
    setLoginPassword("");
    setDisplayEmailReset(false);
  }, [newSignup]);
  /*fetch(saveSignup, {
        method: "POST",
        body: JSON.stringify(signupSave),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);*/

  function createAccount() {
    if (!loginEmail || !loginPassword || !loginUserName) {
      window.alert("Set a valid username, password and email");
    } else {
      /*const fetch_add_user =
        devPort +
        "system_add_user/" +
        loginUserName +
        "/" +
        loginEmail +
        "/" +
        loginPassword;*/
      console.log("LAUNCHING");
      cryptoField(loginPassword).then((dataPass) => {
        if (dataPass) {
          cryptoField(loginEmail).then((dataMail) => {
            if (dataMail) {
              cryptoField(loginUserName).then((dataUser) => {
                if (dataUser) {
                  const encryptedPassword = dataPass;
                  const encryptedEmail = dataMail;
                  const encryptedUserName = dataUser;

                  const fetch_add_user = devPort + "system_add_user";
                  console.log(fetch_add_user);
                  const system_save_player = {
                    username: encryptedUserName,
                    password: encryptedPassword,
                    email: encryptedEmail,
                  };
                  fetch(fetch_add_user, {
                    method: "POST",
                    body: JSON.stringify(system_save_player),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                    .then((response) => {
                      return response.json();
                    })
                    .then((data) => {
                      if (
                        data &&
                        data["message"] &&
                        data["message"] === "Account Created"
                      ) {
                        setUserName(loginUserName);
                      } else {
                        if (
                          data &&
                          data["message"] &&
                          data["message"] === "Already Exists"
                        ) {
                          setSnackMessage(signupError);
                          setAlertMessage(true);
                        } else {
                          setSnackMessage(accountCreationError);
                          setAlertMessage(true);
                        }
                      }
                    });
                }
              });
            }
          });
        }
      });
    }
  }
  function verifyAccount() {
    if (!loginPassword || !loginUserName) {
      window.alert("Set a valid username and password");
    } else {
      /*const fetch_verify_user =
        devPort + "system_login_user/" + loginUserName + "/" + loginPassword;*/
      cryptoField(loginPassword).then((dataPass) => {
        if (dataPass) {
          cryptoField(loginUserName).then((dataUser) => {
            if (dataUser) {
              const encryptedUsername = loginUserName;
              const encryptedPassword = loginPassword;
              const fetch_verify_user = devPort + "system_login_user";
              const system_login_player = {
                username: encryptedUsername,
                password: encryptedPassword,
              };
              console.log(fetch_verify_user);
              fetch(fetch_verify_user, {
                credentials: "include",
                mode: "cors",
                method: "POST",
                body: JSON.stringify(system_login_player),
                /*headers: {
          "Content-Type": "application/json",
        },*/
              })
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                  console.log("CHECKING content");
                  console.log(data);
                  if (data && data["data"]) {
                    setUserName(loginUserName);
                    //setToken(data);
                  } else {
                    setSnackMessage(data["message"]);
                    setAlertMessage(true);
                  }
                });
            }
          });
        }
      });
    }
  }

  function resetUserEmail() {
    cryptoField(loginEmail).then((dataEmail) => {
      if (dataEmail) {
        const encryptedEmail = dataEmail;
        const fetch_reset_password = devPort + "system_reset_email";
        const system_reset_email = {
          email: encryptedEmail,
        };
        console.log(fetch_reset_password);
        fetch(fetch_reset_password, {
          credentials: "include",
          mode: "cors",
          method: "POST",
          body: JSON.stringify(system_reset_email),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data && data["message"] && data["message"] === "Email found") {
              setSnackMessageSuccess("Check your email for a reset link");
              setAlertMessageSuccess(true);
            } else {
              setSnackMessage("No account found associated with that email");
              setAlertMessage(true);
            }
          });
      }
    });
  }
  return (
    <Grid2 container xs={12}>
      <Grid2 xs={9} xsOffset={1.5}>
        <Paper elevation={24}>
          <Card variant="elevation">
            <CardContent>
              <Stack spacing={2}>
                <h5> Chukkerbook Login</h5>
                <TextField
                  id="custom-username"
                  label="Username"
                  style={{ width: "100%" }}
                  onChange={(input) => {
                    console.log(input.target.value);
                    setLoginUserName(input.target.value);
                  }}
                />
                {newSignup && <EmailBox setLoginEmail={setLoginEmail} />}
                <FormControl style={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={loginPassword ? loginPassword : ""}
                    onChange={(input) => {
                      console.log(input.target.value);
                      setLoginPassword(input.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          //onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={newSignup ? createAccount : verifyAccount}
                >
                  {newSignup ? "Create account" : "Login"}
                </Button>
                {!newSignup && (
                  <Stack>
                    <Typography
                      style={{
                        textAlign: "center",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                      variant="body2"
                      onClick={() => setDisplayEmailReset(true)}
                    >
                      Forgotten Password?
                    </Typography>
                  </Stack>
                )}
                {!newSignup && displayEmailReset && (
                  <Stack>
                    <EmailBox setLoginEmail={setLoginEmail} />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={resetUserEmail}
                    >
                      Reset Password
                    </Button>
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Paper>
      </Grid2>
    </Grid2>
  );
}

export default UserLogin;
