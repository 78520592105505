import moment from "moment";
import {
  Button,
  Card,
  CardContent,
  Stack,
  Modal,
  Box,
  Typography,
  TextField,
  Snackbar,
  Alert,
  LinearProgress,
  Autocomplete,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import Layout from "../components/layout/Layout";
import { useState, useEffect, useCallback } from "react";
import DeletionModal from "../components/ui/DeletionModal";
import DayLessons from "../components/Lessons/DayLesson";
import ProBox from "../components/Signups/ProBox";
import getProMapping from "../components/Signups/getProMapping";
import { useNavigate } from "react-router-dom";
import PricingBox from "../components/Lessons/PricingBox";
import useRefresh from "../components/Refresh/refresh";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

function LessonPage({
  setEventId,
  clubId,
  setGameDate,
  devPort,
  waiverNotifications,
  managerMode,
  setLessonPro,
  setLessonPrice,
  betaMode,
  setPricingTab,
}) {
  let navigate = useNavigate();
  useRefresh(clubId, devPort);
  const [registerModal, setRegisterModal] = useState(false);
  const [registerModalPrice, setRegisterModalPrice] = useState(false);
  const [comingGamesString, setComingGamesString] = useState();
  const [startDate, setStartDate] = useState(
    moment(moment().format("YYYY-MM-DD") + "T00:00")
      .utc()
      .format("YYYY-MM-DDTHH:mm")
  ); //'2020-01-01')
  //const [endDate, setEndDate] = useState("2999-01-01");
  const [registerDate, setRegisterDate] = useState(null);
  const [recurringEndDate, setRecurringEndDate] = useState(null);
  const [registerStartTime, setRegisterStartTime] = useState(null);
  const [registerField, setRegisterField] = useState(null);
  const [lessonDelete, setLessonDelete] = useState();
  const [lessonToDelete, setLessonToDelete] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showOnlyBookedLessons, setShowOnlyBookedLessons] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  //Use python datetime.weekday() int format
  const recurringDaysAvailable = [
    {
      label: "Monday",
      value: 0,
    },
    {
      label: "Tuesday",
      value: 1,
    },
    {
      label: "Wednesday",
      value: 2,
    },
    {
      label: "Thursday",
      value: 3,
    },
    {
      label: "Friday",
      value: 4,
    },
    {
      label: "Saturday",
      value: 5,
    },
    {
      label: "Sunday",
      value: 6,
    },
  ];
  //Pro Mapping
  const [proMapping, setProMapping] = useState();
  //const [loadingClubPros, setLoadingClubPros] = useState(true);
  useEffect(() => {
    const player_fetcher = devPort + "player_list/" + clubId + "/True";
    getProMapping(
      devPort,
      player_fetcher,
      setProMapping,
      () => {},
      () => {} //setLoadingClubPros
    );
  }, [devPort, clubId]);

  const filterGames = useCallback(() => {
    console.log("AAAAAAAAAA");
    if (proMapping) {
      console.log("BBBBBBBBBBB");
      console.log(devPort + "lesson_list/" + clubId + "/" + startDate);
      fetch(devPort + "lesson_list/" + clubId + "/" + startDate)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("DATA");
          console.log(data);
          let newLessons = [];
          var dayLessons = {};
          data.map((lesson) => {
            //const newGames = Object.entries(game) data.map((game) => {
            //Moment is setting the day to one day before when time is 00?
            //return [game[0], game[1], moment(game[3]).add(1, "days").format('YYYY-MM-DD')]
            // TODO: Jeremie to refactor this sketchy thing
            let dateOfGame;
            //let dayLessons = [];
            //data[gameDay].map((lesson) => {
            dateOfGame = moment(lesson[4]).format("MM-DD-YYYY");
            //TODO update with correct columns info
            if (!dayLessons[dateOfGame]) {
              dayLessons[dateOfGame] = [];
            }
            console.log(lesson);
            if (!showOnlyBookedLessons || lesson[8] > 0) {
              dayLessons[dateOfGame].push({
                id: lesson[0],
                //lesson_name: lesson[1],
                //day: moment(lesson[3]).add(1, "days").format("dddd, MMMM Do YYYY"),
                pro: proMapping[lesson[6]],
                proId: lesson[6],
                slots: lesson[7],
                available: lesson[7] - lesson[8],
                time: moment(lesson[4]).format("HH:mm"),
                date: moment(lesson[4]).format("dddd MMMM Do YYYY, HH:mm"),
                lessonPrice: lesson[9] !== "null" ? lesson[9] : null,
                //other: lesson
              });
            }
            return null;
          });
          console.log("THERE");
          console.log(dayLessons);
          Object.keys(dayLessons)
            .sort()
            .map((dateOfGame) => {
              newLessons.push({
                day: moment(dateOfGame, "MM-DD-YYYY").format("dddd"),
                date: dateOfGame,
                lessons: dayLessons[dateOfGame].sort(
                  (a, b) => moment(a.time, "HH:mm") - moment(b.time, "HH:mm")
                ),
              });
              return null;
            });
          if (newLessons.length === 0) {
            let emptyMessage = "available";
            if (showOnlyBookedLessons) {
              emptyMessage = "booked";
            }
            setComingGamesString(
              "No lessons " + emptyMessage + " for the selected week"
            );
          } else {
            setComingGamesString("Upcoming Lessons");
          }
          console.log(newLessons);
          setUpcomingLessons(newLessons);
          setIsLoading(false);
        });
    }
  }, [clubId, devPort, startDate, proMapping, showOnlyBookedLessons]);

  useEffect(() => {
    filterGames();
  }, [filterGames]);

  function verifyFirstPageFields() {
    if (!registerDate || !registerStartTime) {
      window.alert("Enter a date and time");
    } else {
      if (!registerField) {
        window.alert("Enter a field name");
      } else {
        if (!idPro) {
          window.alert("Select a coach for the lesson");
        } else {
          setRegisterModal(false)
          setRegisterModalPrice(true);
        }
      }
    }
  }
  function registerLesson() {
    if (!registerDate || !registerStartTime) {
      window.alert("Enter a date and time");
    } else {
      if (!registerField) {
        window.alert("Enter a field name");
      } else {
        if (!idPro) {
          window.alert("Select a coach for the lesson");
        } else {
          const utcDay = moment(
            moment(
              "2024-01-15T" + moment(registerStartTime["$d"]).format("HH:mm")
            ).format("YYYY-MM-DDTHH:mm")
          )
            .utc()
            .format("DDD");
          const localDay = moment(
            moment(
              "2024-01-15T" + moment(registerStartTime["$d"]).format("HH:mm")
            ).format("YYYY-MM-DDTHH:mm")
          ).format("DDD");
          const offSet = utcDay - localDay;

          let recurring_integer = null;
          if (recurringDays.length !== 0) {
            for (let index = 0; index < recurringDays.length; index++) {
              const utcWeekDay =
                (((recurringDays[index] + offSet) % 7) + 7) % 7;
              console.log("UTC WEEK Day");
              console.log(utcWeekDay);
              if (!recurring_integer) {
                recurring_integer = utcWeekDay;
              } else {
                recurring_integer += "_" + utcWeekDay;
              }
            }
          }
          console.log("UTC TIME");
          console.log(recurring_integer);
          const utc_lesson_time = moment(
            registerDate + " " + moment(registerStartTime["$d"]).format("HH:mm")
          )
            .utc()
            .format("YYYY-MM-DD HH:mm");
          console.log(utc_lesson_time);
          const lesson_data = {
            club_id: clubId,
            lesson_datetime: utc_lesson_time,
            field_location: registerField,
            coach_id: idPro,
            max_participants: registerSlots,
            recurring_end_date: recurringEndDate,
            recurring_days: recurring_integer,
            lesson_price: selectedPrice ? selectedPrice.value : null,
            lesson_packages: selectedPackages ? selectedPackages : null,
            lesson_recipient: recipient ? recipient.value : null,
          };
          fetch(devPort + "add_lesson", {
            mode: "cors",
            credentials: "include",
            method: "POST",
            body: JSON.stringify(lesson_data),
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              console.log("Generated Lesson");
              console.log(data);
              setRegisterModalPrice(false);
              setIsLoading(true);
              filterGames();
              //setMessageContent("added to");
              //setAlertMessage(true);
              resetRecurringParams();
              if (data && data["message"]) {
                setMessageContent(data["message"]);
                setAlertMessage(true);
              }
            });
        }
      }
    }
  }
  function deleteLesson(lesson_id) {
    fetch(devPort + "delete_lesson/" + lesson_id).then((data) => {
      setDeleteModal(false);
      setIsLoading(true);
      filterGames();
      setMessageContent("Lesson has been deleted the schedule!");
      setAlertMessage(true);
    });
  }
  const [alterMessage, setAlertMessage] = useState(false);
  const [messageContent, setMessageContent] = useState(
    "Lesson has been added to the schedule!"
  );
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  /*const [upcomingLessons, setUpcomingLessons] = useState([
    { day: "Monday", date: "10/09/2023", lessons: [] },
    {
      day: "Tuesday",
      date: "10/10/2023",
      lessons: [
        { id: 32, pro: "Santos", slots: 2, available: 1, time: "9:30am" },
        { id: 33, pro: "Doug", slots: 3, available: 1, time: "12:30pm" },
        { id: 34, pro: "Santos", slots: 4, available: 0, time: "2:30pm" },
      ],
    },
  ]);*/
  const [idPro, setIdPro] = useState();
  const [registerSlots, setRegisterSlots] = useState(2);
  const numberChukkers = [];
  var i;
  for (i = 1; i <= 10; i++) {
    numberChukkers.push({ label: i, value: i });
  }
  const [recurringEvent, setRecurringEvent] = useState(false);
  const [recurringDays, setRecurringDays] = useState([]);
  function updateRecurringDays(listDays) {
    const newDays = listDays.map((day) => {
      return day.value;
    });
    setRecurringDays(newDays);
  }
  useEffect(() => {
    if (lessonToDelete) {
      setDeleteModal(true);
    }
  }, [lessonToDelete]);
  function resetRecurringParams() {
    setRecurringEvent(false);
    setRegisterDate(null);
    const utc_start_date = moment(moment().format("YYYY-MM-DD") + "T00:00")
      .utc()
      .format("YYYY-MM-DDTHH:mm");
    setStartDate(utc_start_date);
    setRegisterField(null);
    setRecurringEndDate(null);
    setRecurringDays([]);
    setIdPro(null);
    setSelectedPrice();
    setSelectedPackages()
  }
  const [selectedPrice, setSelectedPrice] = useState();
  const [selectedPackages, setSelectedPackages] = useState()
  const [recipient, setRecipient] = useState();
  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications}>
      <Snackbar
        open={alterMessage}
        autoHideDuration={3000}
        onClose={() => {
          setAlertMessage(false);
        }}
      >
        <Alert
          severity={
            messageContent.match("does not") || messageContent.match("has not")
              ? "error"
              : "success"
          }
        >
          {messageContent}
        </Alert>
      </Snackbar>

      <Card variant="outlined">
        <CardContent>
          <Stack spacing={3}>
            <DatePicker
              label="Select Week"
              onChange={(event) => {
                const utc_start = moment(
                  moment(event["$d"]).format("YYYY-MM-DD") + "T00:00"
                )
                  .utc()
                  .format("YYYY-MM-DDTHH:mm");
                setStartDate(utc_start);
                console.log(utc_start);
                console.log(event["$d"]);
              }}
            />

            {/*<Grid2 xs={4}>
                <Button
                  variant="contained"
                  size="large"
                  color="success"
                  onClick={filterGames}
                >
                  Show Available Lessons
                </Button>
              </Grid2>
                */}
            <DeletionModal
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              deleteString="Lesson"
              deleteId={lessonDelete}
              setDeleteId={setLessonToDelete}
              onDelete={() => deleteLesson(lessonToDelete)}
            />
            {managerMode === -1 && (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      setRegisterModal(true);
                    }}
                  >
                    Create Lesson
                  </Button>
                )}
            {managerMode === -1 && (
              <Button
                onClick={() => {
                  setPricingTab("Lesson");
                  navigate("/lessonmanagepricing");
                }}
                variant="outlined"
                size="large"
                color="primary"
              >
                Set Lesson Pricing
              </Button>
            )}
            
            {!isLoading && (
              <Stack spacing={3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showOnlyBookedLessons}
                        onChange={(event) => {
                          setShowOnlyBookedLessons(!showOnlyBookedLessons);
                        }}
                      />
                    }
                    label="Only Show Booked Lessons"
                  />
                </FormGroup>
                <h3>{comingGamesString}</h3>
                {upcomingLessons.map((day) => {
                  return (
                    <DayLessons
                      key={day.date}
                      day={day.day}
                      date={day.date}
                      lessons={day.lessons}
                      setLessonDelete={setLessonDelete}
                      setLessonToDelete={setLessonToDelete}
                      managerMode={managerMode}
                      setEventId={setEventId}
                      setLessonPro={setLessonPro}
                      setLessonPrice={setLessonPrice}
                      setGameDate={setGameDate}
                    />
                  );
                })}
                <Modal
                  open={registerModalPrice}
                  onClose={() => {
                    setRegisterModalPrice(false);
                  }}
                  aria-labelledby="Lesson Registration2"
                  aria-describedby="Create new lesson2"
                  style={{ overflow: "scroll" }}
                >
                  <Box sx={style}>
                    <Stack spacing={2}>
                      <Typography
                        id="lesson-creation"
                        variant="h6"
                        component="h2"
                      >
                        Create New Lesson 2/2
                      </Typography>
                      <PricingBox
                        devPort={devPort}
                        clubId={clubId}
                        selectedPrice={selectedPrice}
                        setSelectedPrice={setSelectedPrice}
                        selectedPackages={selectedPackages}
                        setSelectedPackages={setSelectedPackages}
                        recipient={recipient}
                        setRecipient={setRecipient}
                        proMapping={proMapping}
                        tableType="Lesson"
                      />
                      <Grid2 container xs={12}>
                        <Grid2 xs={5.5}>
                          <Button
                            style={{ width: "100%" }}
                            variant="contained"
                            color="success"
                            onClick={registerLesson}
                          >
                            Save Lesson
                          </Button>
                        </Grid2>
                        <Grid2 xs={5.5} xsOffset={1}>
                          <Button
                            style={{ width: "100%" }}
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setRegisterModalPrice(false);
                              resetRecurringParams();
                            }}
                          >
                            <CloseIcon />
                            Close
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Stack>
                  </Box>
                </Modal>
                <Modal
                  open={registerModal}
                  onClose={() => {
                    setRegisterModal(false);
                  }}
                  aria-labelledby="Lesson Registration"
                  aria-describedby="Create new lesson"
                  style={{ overflow: "scroll" }}
                >
                  <Box sx={style}>
                    <Stack spacing={2}>
                      <Typography
                        id="lesson-creation"
                        variant="h6"
                        component="h2"
                      >
                        Create New Lesson 1/2
                      </Typography>
                      <DatePicker
                        data-testid="lesson-date"
                        onChange={(event) => {
                          setRegisterDate(
                            moment(event["$d"]).format("YYYY-MM-DD")
                          );
                        }}
                      />
                      <TimePicker
                        data-testid="lesson-time"
                        label="Set Start Time"
                        onChange={(event) => {
                          setRegisterStartTime(event);
                        }}
                      />
                      <TextField
                        data-testid="lesson-field-name"
                        id="field-name"
                        label="Field Name"
                        variant="filled"
                        onChange={(event) => {
                          setRegisterField(event.target.value);
                        }}
                      />
                      <div>Max Number of Students</div>
                      <Box sx={{ width: 325 }}>
                        <Slider
                          aria-label="Max Number of students"
                          data-testid="number-students"
                          min={1}
                          max={10}
                          defaultValue={2}
                          step={1}
                          onChange={(event) => {
                            setRegisterSlots(event.target.value);
                          }}
                          valueLabelDisplay="auto"
                          marks={numberChukkers}
                        />
                      </Box>
                      <ProBox
                        devPort={devPort}
                        clubId={clubId}
                        setIdPro={setIdPro}
                        boxLabel="Select Coach"
                        //refresh={players}
                      />
                      {/*<PricingBox
                        devPort={devPort}
                        clubId={clubId}
                        selectedPrice={selectedPrice}
                        setSelectedPrice={setSelectedPrice}
                        recipient={recipient}
                        setRecipient={setRecipient}
                        proMapping={proMapping}
                        tableType="Lesson"
                        />*/}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={recurringEvent}
                              onChange={(event) =>
                                setRecurringEvent(!recurringEvent)
                              }
                            />
                          }
                          label="Recurring Lesson"
                        />
                      </FormGroup>
                      <Autocomplete
                        multiple
                        id="recurring-days"
                        options={recurringDaysAvailable}
                        //getOptionLabel={(option) => option.title}
                        disabled={!recurringEvent}
                        //defaultValue={recurringDays}
                        getOptionDisabled={(option) =>
                          recurringDays.includes(option.value)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.value === value
                        }
                        onChange={(event, newValue) =>
                          updateRecurringDays(newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Recur Every"
                            placeholder="Day"
                          />
                        )}
                      />
                      <DatePicker
                        label="Stop On"
                        onChange={(event) => {
                          setRecurringEndDate(
                            moment(event["$d"]).format("YYYY-MM-DD")
                          );
                        }}
                        disabled={!recurringEvent}
                      />
                      <Grid2 container xs={12}>
                        <Grid2 xs={5.5}>
                          <Button
                            style={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            onClick={verifyFirstPageFields} //registerLesson}
                          >
                            <NavigateNextIcon />
                            Next
                          </Button>
                        </Grid2>
                        {/*<Button
                          variant="contained"
                          color="success"
                          onClick={registerLesson}
                      >
                          Save Lesson
                      </Button>*/}
                        <Grid2 xs={5.5} xsOffset={1}>
                          <Button
                            style={{ width: "100%" }}
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setRegisterModal(false);
                              resetRecurringParams();
                            }}
                          >
                            <CloseIcon />
                            Close
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Stack>
                  </Box>
                </Modal>
              </Stack>
            )}
            {isLoading && <LinearProgress color="secondary" />}
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default LessonPage;
