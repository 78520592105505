import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClubSettingsCard from "./ClubSettingsCard";

function ClubSettingsAccordion({ devPort, clubId, clubName, setSubscriptionMode, setClubId }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon data-testid={"settings-club-" + clubName}/>}
        //aria-controls="panel1-content"
        id={"settings-club-" + clubName}
        //data-testid={"settings-club-" + clubName}
      >
        {"Settings for Club " + clubName}
      </AccordionSummary>
      <AccordionDetails>
        <ClubSettingsCard
          key={clubId}
          devPort={devPort}
          clubId={clubId}
          clubName={clubName}
          setSubscriptionMode={setSubscriptionMode}
          setClubId={setClubId}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default ClubSettingsAccordion;
