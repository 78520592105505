import { Alert, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import PlayerList from "../components/PlayerManagement/PlayerList";
import useRefresh from "../components/Refresh/refresh";

function PlayerPage({ clubId, devPort, waiverNotifications, setWaiverNotifications, managerMode }) {
  const [players, setPlayers] = useState();
  const [loadingPlayers, setLoadingPlayers] = useState(true);
  useRefresh(clubId, devPort);
  const loadPlayers = useCallback(() => {
    fetch(devPort + "player_list/" + clubId + "/False")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let formattedData = []
        data.map((player) => {
          formattedData.push({
            first_name: player[0],
            last_name: player[1],
            uspa_rating: player[2],
            club_rating: player[3],
            player_id: player[4],
            is_club_pro: player[5],
            has_chukkerbook_account: player[6],
            up_to_date_waiver: player[7],
            is_legacy_system: player[8]
          })
          return null
        })
        console.log("HERE I AM")
        console.log(formattedData);
        setPlayers(formattedData);
        setLoadingPlayers(false);
      });
  }, [devPort, clubId]);
  useEffect(() => {
    loadPlayers();
  }, [loadPlayers]);
  function addNewPlayer() {
    loadPlayers();
    setAlertMessage(true);
  }
  function deletePlayer(playerId) {
    fetch(devPort + "/delete_player/" + playerId + "/" + clubId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        loadPlayers();
      });
  }
  const [alterMessage, setAlertMessage] = useState(false);
  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications}>
      <div>
        <Snackbar
          open={alterMessage}
          autoHideDuration={3000}
          onClose={() => {
            setAlertMessage(false);
          }}
        >
          <Alert severity="success"> Player has been added to the club!</Alert>
        </Snackbar>
        <PlayerList
          devPort={devPort}
          loadingPage={loadingPlayers}
          managerMode={managerMode}
          title="All Club Members"
          clubId={clubId}
          players={players}
          nameEdit={true}
          ratingEdit={true}
          chukkerEdit={false}
          refresh={addNewPlayer}
          playerDelete={deletePlayer}
          waiverNotifications={waiverNotifications}
          setWaiverNotifications={setWaiverNotifications}
          tableMode="members"
        />
      </div>
    </Layout>
  );
}
export default PlayerPage;
