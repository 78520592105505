import { Button, Card, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { GoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../components/layout/Layout";
import processToken from "../components/membership/processToken";
import SnackError from "../components/Signups/SnackError";
import UserLogin from "../components/membership/UserLogin";
import SnackSuccess from "../components/Signups/SnackSuccess";

function GoogleLoginPage({
  devPort,
  userName,
  setUserName,
  setPlayerBoard,
  newSignUp,
  setCurrentMembership,
  alertMessageSuccess,
  setAlertMessageSuccess,
  betaMode,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const appleSignInCode = searchParams.get("code");
  console.log("HERE");
  console.log(userName);
  useEffect(() => {
    if (appleSignInCode) {
      const apple_query = newSignUp ? "account-signup" : "account-login";
      processToken(
        appleSignInCode,
        "get_apple_id/" + apple_query + "/",
        devPort,
        setUserName
      );
    }
  }, [appleSignInCode, devPort, newSignUp, setUserName]);
  const isUserSubscribed = useCallback(() => {
    console.log(userName);
    console.log("Processing name");
    //TODO user Stripe user ID
    //fetch(devPort + "is-subscriber/" + userName)
    fetch(devPort + "system_get_manager/" + userName)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data[0][0]) {
          //.data && data.data.length != 0) {
          setCurrentMembership("manager");
          if (newSignUp) {
            console.log("ACCOUNT ALREADY EXISTS");
            setSnackMessage(signupError);
            setAlertMessage(true);
          } else {
            console.log("ACCOUNT FOUND");
            navigate("/club");
          }
          //setHasAccount(true);
        } else {
          const fetchUserId = devPort + "system_get_player/" + userName;
          fetch(fetchUserId)
            .then((idResponse) => {
              return idResponse.json();
            })
            .then((idData) => {
              if (newSignUp) {
                if (idData && idData[0][0]) {
                  setCurrentMembership("player");
                  console.log("ACCOUNT ALREADY EXISTS");
                  setSnackMessage(signupError);
                  setAlertMessage(true);
                } else {
                  navigate("/selectmembership");
                }
              } else {
                if (idData && idData[0][0]) {
                  console.log("Player Account Found");
                  setCurrentMembership("player");
                  //TODO UPDATE TO PLAYER CLUB
                  navigate("/playerclubselection");
                } else {
                  console.log("NO ACCOUNT FOUND");
                  setSnackMessage(loginError);
                  //setUserName("");
                  setCurrentMembership("");
                  setAlertMessage(true);
                  navigate("/selectmembership");
                }
              }
            });
        }
      });
  }, [
    devPort,
    navigate,
    newSignUp,
    setCurrentMembership,
    userName,
  ]);

  const firstUpdate = useRef(true);
  console.log(userName);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      setUserName("");
      setPlayerBoard(-1);
      setCurrentMembership("");
      return;
    }
    if (userName) {
      isUserSubscribed();
    }
  }, [
    userName,
    newSignUp,
    setUserName,
    setCurrentMembership,
    isUserSubscribed,
    setPlayerBoard
  ]);
  const [alterMessage, setAlertMessage] = useState(false);
  const loginError =
    "No account found. Use another account or signup for a new account";
  const signupError = "Account already exists! Go to the login page instead";
  const [snackMessage, setSnackMessage] = useState(loginError);
  const [snackMessageSuccess, setSnackMessageSuccess] = useState(
    "Email has been successfully reset"
  );

  return (
    <Layout managerMode={-2}>
      <SnackError
        alterMessage={alterMessage}
        setAlertMessage={setAlertMessage}
        snackMessage={snackMessage}
      />
      <SnackSuccess
        alterMessage={alertMessageSuccess}
        setAlertMessage={setAlertMessageSuccess}
        snackMessage={snackMessageSuccess}
      />
      <Card>
        <CardContent>
          <Stack spacing={6}>
            <Stack spacing={3} textAlign="center">
              {!newSignUp && <h1> User Login</h1>}
              {newSignUp && <h1> User Signup</h1>}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GoogleLogin
                  onSuccess={(data) => {
                    processToken(
                      data.credential,
                      "get_google_id/",
                      devPort,
                      setUserName
                    );
                  }}
                  theme="outline"
                  shape="circle"
                  text={newSignUp ? "signup_with" : "signin_with"}
                  locale="en_US"
                  onError={() => {
                    console.log("Invalid login");
                  }}
                />
              </div>
              {newSignUp && (
                <AppleLogin
                  clientId="com.chukkerbook.signin"
                  redirectURI="https://chukkerbook.com/account-signup"
                  responseMode="query"
                  designProp={{
                    width: "220",
                    height: "50",
                    border: false,
                    type: "sign-in",
                  }}
                />
              )}
              {!newSignUp && (
                <AppleLogin
                  clientId="com.chukkerbook.signin"
                  redirectURI="https://chukkerbook.com/account-login"
                  responseMode="query"
                  designProp={{
                    width: "220",
                    height: "50",
                    border: false,
                    type: "sign-in",
                  }}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/*<FaceBookLogin
                  appId="1088597931155576"
                  autoLoad={true}
                  fields="name,email,picture"
                  size="metro"
                  textButton={newSignUp ? "Sign Up with Facebook" : "Sign In with Facebook" }
                  //onClick={componentClicked}
                  //callback={responseFacebook}
              />*/}
              </div>
              {betaMode && (
                <UserLogin
                  devPort={devPort}
                  newSignup={newSignUp}
                  setUserName={setUserName}
                  setAlertMessage={setAlertMessage}
                  setSnackMessage={setSnackMessage}
                  setAlertMessageSuccess={setAlertMessageSuccess}
                  setSnackMessageSuccess={setSnackMessageSuccess}
                  signupError={signupError}
                  //setToken={setToken}
                />
              )}
            </Stack>
            {!newSignUp && (
              <Stack>
                <Typography style={{ textAlign: "center" }} variant="body2">
                  Not a member yet?
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("/account-signup")}
                >
                  Signup
                </Button>
              </Stack>
            )}
            {newSignUp && (
              <Stack>
                <Typography style={{ textAlign: "center" }} variant="body2">
                  Already have an account?
                </Typography>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => navigate("/account-login")}
                >
                  Login
                </Button>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default GoogleLoginPage;
