import TeamItem from "./TeamItem";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
function TeamList(props) {
  return (
    <Grid2 container spacing={6}>
      {Object.entries(props.teamList).map(([key, value]) => {
        return (
          <Grid2 xs={6} key={key}>
              <TeamItem
                id={key}
                managerMode={props.managerMode}
                players={value}
                chukkerId={props.chukkerId}
                referencePlayer={props.referencePlayer}
                useUSPARating={props.useUSPARating}
              />
              </Grid2>
        );
      })}
    </Grid2>
  );
}

export default TeamList;
