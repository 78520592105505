import { Card, CardContent } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import Layout from "../components/layout/Layout";
import SnackError from "../components/Signups/SnackError";
import UserContact from "../components/membership/UserContact";

function ContactPage({ devPort, setAlertMessageSuccess }) {
  const [alertMessage, setAlertMessage] = useState(false);
  return (
    <Layout managerMode={-2}>
      <Card>
        <CardContent>
          <SnackError
            alterMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            snackMessage={
              "An error has occured while sending your message. Please try again"
            }
          />
          <Stack spacing={3} textAlign="center">
            <h1> Information Request About Chukkerbook</h1>
            <UserContact
              devPort={devPort}
              setAlertMessage={setAlertMessage}
              setAlertMessageSuccess={setAlertMessageSuccess}
            />
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default ContactPage;
