import moment from "moment";

function getLessonsByClub(devPort, clubId, setUpcomingLessons, proMapping) {
  const startDate = moment(moment().format("YYYY-MM-DD") + "T00:00")
    .utc()
    .format("YYYY-MM-DDTHH:mm");
  fetch(devPort + "event_list/" + clubId + "/" + startDate)
    .then((response) => {
      console.log(response.json);
      return response.json();
    })
    .then((data) => {
      console.log("Inside the fc");
      console.log(data);
      let newLessons = [];
      var dayLessons = {};
      if (data) {
        data.map((lesson) => {
          let dateOfLesson;

          dateOfLesson = moment(lesson[4]).format("MM-DD-YYYY");

          if (!dayLessons[dateOfLesson]) {
            dayLessons[dateOfLesson] = [];
          }

          dayLessons[dateOfLesson].push({
            id: lesson[1],
            pro: proMapping[lesson[6]],
            proId: lesson[6],
            slots: lesson[7],
            available: lesson[7] - lesson[8],
            time: moment(lesson[4]).format("HH:mm"),
            date: moment(lesson[4]).format("dddd MMMM Do YYYY, HH:mm"),
            lessonPrice: lesson[9] !== "null" ? lesson[9] : null,
            event_type: lesson[0],
            field_location: lesson[2],
            game_type: lesson[11],
            manager_notes: lesson[10],
          });
          return null;
        });
      }
      if (dayLessons) {
        console.log(dayLessons);
        Object.keys(dayLessons)
          .sort()
          .map((dateOfLesson) => {
            newLessons.push({
              day: moment(dateOfLesson, "MM-DD-YYYY").format("dddd"),
              date: dateOfLesson,
              lessons: dayLessons[dateOfLesson].sort(
                (a, b) => moment(a.time, "HH:mm") - moment(b.time, "HH:mm")
              ),
            });
            console.log("I get here");
            console.log(newLessons);
            setUpcomingLessons(newLessons);
            return newLessons;
          });
      }
    });
}

export default getLessonsByClub;
