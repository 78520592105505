function postProcessing(fetch_cmd, fetch_json, customCallback) {
  //fetch(devPort + "/delete_player/" + playerId + "/" + clubId)
  fetch(fetch_cmd, {
    mode: "cors",
    method: "POST",
    body: JSON.stringify(fetch_json),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      customCallback();
    });
}

export default postProcessing;
