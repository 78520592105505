import ChukkerItem from './ChukkerItem';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
//<ul className={classes.list} ref={props.reference}>
function ChukkerList(props) {
    return (<Grid2 container spacing={2}>
        {Object.entries(props.chukkerList).map(([key, value]) => {
            return <Grid2 xs={12} key={key}>
                <ChukkerItem
                id={key}
                managerMode={props.managerMode}
                teams={value}
                referenceTeam={props.referenceTeam}
                referencePlayer={props.referencePlayer}
                useUSPARating={props.useUSPARating}
            />
            </Grid2>
        })}
    </Grid2>
    )
}

export default ChukkerList;