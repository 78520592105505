import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";

function SettingsMenu({ devPort, anchorEl, setAnchorEl }) {
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => navigate("/user-settings")}>Settings</MenuItem>
      <MenuItem
        onClick={() => {
          userLogout(devPort);
          navigate("/account-login");
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
}
export default SettingsMenu;
