import {
  Card,
  CardContent,
  Fab,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import SchoolIcon from "@mui/icons-material/School";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";

function IndividualLesson({
  lesson,
  setLessonDelete,
  setLessonToDelete,
  managerMode,
  setEventId,
  setLessonPro,
  setLessonPrice,
  clubId,
  setClubId,
  setGameDate,
}) {
  let navigate = useNavigate();
  //<Typography color="secondary" variant="h5">{lesson.event_type}</Typography>
  const theme = useTheme();
  const stylers = {
    backgroundColor: "white",
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
    },
  };
  return (
    <Card
      variant="outlined"
      style={{ backgroundColor: "rgba(93, 138, 168, 0.10)" }}
      //style={{ backgroundColor: "rgba(128, 0, 32, 0.10)" }}
      //style={{ borderColor: "#800020", borderWidth: "medium" }}
    >
      <CardContent>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <SchoolIcon color="secondary" />
            <Typography variant="body1" color="secondary">
              Lesson
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              gap={4}
              style={{ marginRight: 0, marginLeft: "auto" }}
            >
              {managerMode === -1 && !lesson.event_type && (
                <Fab
                  style={stylers}
                  color="primary"
                  size="small"
                  onClick={() => {
                    setLessonDelete(
                      "with " + lesson.pro + " at " + lesson.time
                    );
                    setLessonToDelete(lesson.id);
                    console.log(lesson);
                  }}
                  fullWidth={true}
                >
                  <DeleteIcon />
                </Fab>
              )}
              <Fab
                variant="contained"
                size="small"
                onClick={() => {
                  if (clubId) {
                    setClubId(clubId);
                  }
                  setEventId(lesson.id);
                  setLessonPro(lesson.proId);
                  setLessonPrice(lesson.lessonPrice);
                  setGameDate(lesson.date);
                  navigate("/lessonsignup");
                }}
                color="success"
                fullWidth={true}
              >
                <VisibilityIcon />
              </Fab>
            </Stack>
          </Stack>
          <Stack gap={1}>
            <Stack direction="row">
              <Typography color="primary">{"Coach"}</Typography>
              <Typography style={{ marginRight: 0, marginLeft: "auto" }}>
                {lesson.pro}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography color="primary">{"Time"}</Typography>
              <Typography style={{ marginRight: 0, marginLeft: "auto" }}>
                {lesson.time}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography color="primary">{"Available Slots"}</Typography>
              <Typography
                color={lesson.available === 0 ? "secondary" : "green"}
                style={{ marginRight: 0, marginLeft: "auto" }}
              >
                {lesson.available + "/" + lesson.slots}
              </Typography>
            </Stack>
            {/*<Grid2 xs={managerMode === -1 && !lesson.event_type ? 5.5 : 12}>
              <Button
                variant="contained"
                onClick={() => {
                  if (clubId) {
                    setClubId(clubId);
                  }
                  setEventId(lesson.id);
                  setLessonPro(lesson.proId);
                  setLessonPrice(lesson.lessonPrice);
                  setGameDate(lesson.date);
                  //if (managerMode === -1) {
                  navigate("/lessonsignup");
                  //} else {
                  //  navigate("/playerlessonsignup");
                  //}
                }}
                color="success"
                //disabled={lesson.available === 0}
                fullWidth={true}
              >
                <AssignmentIcon />{" "}
              </Button>
            </Grid2>
            {managerMode === -1 && !lesson.event_type && (
              <Grid2 xs={5.5} xsOffset={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setLessonDelete(
                      "with " + lesson.pro + " at " + lesson.time
                    );
                    setLessonToDelete(lesson.id);
                    console.log(lesson);
                  }}
                  color="secondary"
                  fullWidth={true}
                >
                  <DeleteIcon />{" "}
                </Button>
              </Grid2>
                )}*/}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default IndividualLesson;
