import { Button, Card, CardContent } from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout/Layout";
import MembershipCard from "../components/membership/MembershipCard";
import StripeConnectCard from "../components/membership/StripeConnectCard";
import userLogout from "../components/layout/userLogout";
import { useCallback, useEffect, useState } from "react";
import StripeConnectCardDisabled from "../components/membership/StripeConnectCardDisabled";
import ClubSettingsAccordion from "../components/Settings/ClubSettingsAccordion";

//TODO bug when player clicks on Dashboard!!!!!

function SettingsPage({
  devPort,
  userName,
  currentMembership,
  setCurrentMembership,
  setSubscriptionMode,
  setClubId,
}) {
  const navigate = useNavigate();
  //useEffect(()=>{setUserEmail("")},[])
  const [clubList, setClubList] = useState();
  const [userId, setUserId] = useState();
  const [isAllowedStripeConnect, setIsAllowedStripeConnect] = useState(false);
  useEffect(() => {
    var fetchUserId = devPort + "system_get_manager/" + userName;
    console.log(fetchUserId);
    fetch(fetchUserId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data && data[0][0] !== null) {
          console.log("User ID " + data[0][0]);
          setUserId(data[0][0]);
        }
      });
  }, [devPort, userName]);
  useEffect(() => {
    var fetchUserId = devPort + "system_get_player/" + userName;
    console.log(fetchUserId);
    fetch(fetchUserId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data && data[0][0] !== null) {
          console.log("User ID " + data[0][0]);
          setUserId(data[0][0]);
        }
      });
  }, [devPort, userName]);

  useEffect(() => {
    if (currentMembership === "player" && userId) {
      console.log("Loading Stripe Info");
      var fetchAllowedStripe =
        devPort + "is_player_allowed_stripe_connect/" + userId;
      console.log(fetchAllowedStripe);
      fetch(fetchAllowedStripe)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("Allowed Stripe?");
          if (data && data[0][0] !== null) {
            let newIsAllowedStripeConnect = false;
            data.map((club) => {
              if (club[3]) {
                newIsAllowedStripeConnect = true;
              }
              return null;
            });
            if (newIsAllowedStripeConnect) {
              console.log("YES!");
              console.log(data[0]);
              setIsAllowedStripeConnect(true);
            }
          }
        });
    }
  }, [currentMembership, userId, devPort]);
  const loadClubs = useCallback(() => {
    //console.log(window.reactPress)
    console.log(userId);
    console.log(devPort + "get_clubs/" + userId + "/MANAGER");
    fetch(devPort + "get_clubs/" + userId + "/MANAGER")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const newClubs = data.map((info) => {
          //TODO update with club ID
          return { label: info[1], value: info[0] };
        });
        setClubList(newClubs);
      });
  }, [devPort, userId]);
  useEffect(() => {
    if (currentMembership === "manager" && userId) {
      loadClubs();
    }
  }, [userId, currentMembership, loadClubs]);

  /*function postMan() {
    fetch(devPort + "stripe_webhooks", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(stripeData),
    });
  }*/

  return (
    <Layout
      managerMode={currentMembership === "manager" ? -4 : -5}
      devPort={devPort}
    >
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <h1> User Settings</h1>
            {/*<Button onClick={postMan}>POSTMAN</Button>*/}
            {/*<TextField
              id="username-settings"
              data-testid="username-settings"
              label="Username"
              variant="filled"
              value={userName}
              disabled={true}
  />*/}
            {currentMembership === "manager" && (
              <MembershipCard
                devPort={devPort}
                userName={userName}
                membershipType="Manager"
                setCurrentMembership={setCurrentMembership}
              />
            )}
            {currentMembership === "player" && (
              <MembershipCard
                devPort={devPort}
                userName={userName}
                membershipType="Player"
                setCurrentMembership={setCurrentMembership}
              />
            )}
            {(currentMembership === "manager" || isAllowedStripeConnect) && (
              <StripeConnectCard devPort={devPort} userName={userName} />
            )}
            {currentMembership === "player" && !isAllowedStripeConnect && (
              <StripeConnectCardDisabled />
            )}
            {currentMembership === "manager" && clubList && (
              <Card>
                {clubList.map((club) => (
                  <ClubSettingsAccordion
                    key={club.value}
                    devPort={devPort}
                    clubId={club.value}
                    clubName={club.label}
                    setSubscriptionMode={setSubscriptionMode}
                    setClubId={setClubId}
                  />
                ))}
              </Card>
            )}
            <Button
              variant="contained"
              color="primary"
              data-testid="dashboard-button"
              onClick={() => {
                if (currentMembership === "manager") {
                  navigate("/club");
                } else {
                  navigate("/playerclubselection");
                }
              }}
            >
              Dashboard
            </Button>
            <Button
              variant="contained"
              color="secondary"
              data-testid="logout-button"
              onClick={() => {
                userLogout(devPort);
                navigate("/account-login");
              }}
            >
              Logout
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default SettingsPage;
