import PoloPlayerOrange from "./PoloPlayerOrange.svg";
import PoloPlayerGreen from "./PoloPlayerGreen.svg";
import PoloPlayerBlue from "./PoloPlayerBlue.svg";
import PoloPlayerBrown from "./PoloPlayerBrown.svg";
import PoloPlayerLightBlue from "./PoloPlayerLightBlue.svg";
import PoloPlayerPink from "./PoloPlayerPink.svg";
import PoloPlayerPurple from "./PoloPlayerPurple.svg";
import PoloPlayerRed from "./PoloPlayerRed.svg";
import PoloPlayerYellow from "./PoloPlayerYellow.svg";

const PRO_COLORS = [
    ["orange", PoloPlayerOrange],
    ["green", PoloPlayerGreen],
    ["blue", PoloPlayerBlue],
    ["brown", PoloPlayerBrown],
    ["navy", PoloPlayerLightBlue],
    ["pink", PoloPlayerPink],
    ["purlple", PoloPlayerPurple],
    ["red", PoloPlayerRed],
    ["yellow", PoloPlayerYellow],
  ]; 
export default PRO_COLORS