import { Fab } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Delete({
  playerInfo,
  setPlayerId,
  setPlayerFirstName,
  setPlayerLastName,
  setDeleteModal,
}) {
  return (
    <Fab
      color="error"
      size="small"
      aria-label="delete"
      disabled={playerInfo["paid_lease"] || playerInfo["paid_lesson"]}
      onClick={() => {
        setPlayerId(playerInfo["player_id"]);
        setPlayerFirstName(playerInfo["first_name"]);
        setPlayerLastName(playerInfo["last_name"]);
        setDeleteModal(true);
      }}
    >
      <DeleteIcon />
    </Fab>
  );
}
