import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import HelpIcon from "@mui/icons-material/Help";
import { useEffect, useState } from "react";
import sqlTableMapping from "./sqlTableMapping";
import LessonPackageSelection from "./LessonPackageSelection";

function LessonAddPackage({
  devPort,
  clubId,
  priceType,
  refreshPricing,
  setRefreshPricing,
}) {
  const [lessonNickname, setLessonNickname] = useState();
  const [packageRef, setPackageRef] = useState();
  const [lessonPrice, setLessonPrice] = useState();
  const [currency, setCurrency] = useState("usd");
  const [numberLesson, setNumberLesson] = useState();
  const [unlimitedLesson, setUnlimitedLesson] = useState(false);
  const [useExistingReference, setUseExistingReference] =
    useState("existing_reference");
  const [noReferenceYet, setNoReferenceYet] = useState(false);
  const packageContent =
    priceType === "Membership"
      ? "games"
      : priceType === "Lease"
      ? "horse lease"
      : "lessons";

  useEffect(() => {
    setUseExistingReference("existing_reference");
    setNoReferenceYet(false);
  }, [priceType]);

  useEffect(() => {
    if (noReferenceYet) {
      console.log("RESETTING");
      setUseExistingReference("new_reference");
    } else {
      setUseExistingReference("existing_reference");
    }
  }, [noReferenceYet]);
  function refreshFields() {
    setLessonNickname("");
    setPackageRef("");
    setLessonPrice("");
    setCurrency("usd");
    setNumberLesson("");
  }
  function createPricing() {
    const isFloat = /\d+\.\d+/.test(lessonPrice);
    const isInt = /\d+/.test(lessonPrice);
    const valid_number = isFloat || isInt;
    if (!lessonNickname || !packageRef) {
      window.alert("Enter a name and a reference for the lesson pricing");
    } else {
      if (!lessonPrice || !valid_number) {
        window.alert("Enter a valid price for the lesson");
      } else {
        const isIntCredit = /\d+/.test(numberLesson);
        if (!unlimitedLesson && !isIntCredit) {
          window.alert("Enter a valid number of " + packageContent);
        } else {
          const priceFetch = devPort + "create-lesson-package";
          const priceSave = {
            name: lessonNickname,
            ref: packageRef,
            price: parseFloat(lessonPrice) * 100,
            credit_number: unlimitedLesson ? null : numberLesson,
            currency: currency,
            clubId: clubId,
            tableType: sqlTableMapping[priceType],
          };
          console.log(priceSave);
          fetch(priceFetch, {
            method: "POST",
            body: JSON.stringify(priceSave),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              console.log(data);
              //const stripePriceId = data["default_price"];
              refreshFields();
              setRefreshPricing(!refreshPricing);
            });
        }
      }
    }
  }
  return (
    <Paper elevation={24}>
      <Card variant="elevation">
        <CardContent>
          <Stack spacing={2}>
            <h1>{"Add New " + priceType + " Package"}</h1>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              data-testid="pricing-disclaimer-package"
            >
              Stripe and Chukkerbook charge a total of 3.9% + $0.55 per
              transaction for credit card payments. The fees will be deducted
              from the total price set for the lesson. Please set your pricing
              accordingly.
            </Typography>

            <TextField
              id="add-lesson-nickname"
              label="Add Package Name"
              data-testid="add-package-nickname"
              style={{ width: "100%" }}
              value={lessonNickname}
              onChange={(input) => {
                console.log(input.target.value);
                setLessonNickname(input.target.value);
              }}
            />
            <Card variant="outlined">
              <CardContent>
                <FormControl>
                  <FormLabel
                    id="package-reference-selection"
                    data-testid="package-reference-selection"
                  >
                    Package Reference
                  </FormLabel>
                  {!noReferenceYet && (
                    <RadioGroup
                      row
                      aria-labelledby="radio-package-reference-selection"
                      name="radio-package-reference-selection"
                      data-testid="radio-package-reference-selection"
                      value={useExistingReference}
                      onChange={(event) => {
                        console.log(event.target.value);
                        setUseExistingReference(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="existing_reference"
                        control={<Radio />}
                        label="Use existing reference"
                      />
                      <FormControlLabel
                        value="new_reference"
                        control={<Radio />}
                        label="Create new reference"
                      />
                    </RadioGroup>
                  )}
                </FormControl>
                <Grid2 container xs={12} spacing={2}>
                  <Grid2 xs={10}>
                    {useExistingReference === "new_reference" && (
                      <TextField
                        id="add-package-reference"
                        label="Add Package Reference"
                        data-testid="add-package-reference"
                        style={{ width: "100%" }}
                        value={packageRef}
                        onChange={(input) => {
                          console.log(input.target.value);
                          setPackageRef(input.target.value);
                        }}
                      />
                    )}
                    {useExistingReference === "existing_reference" && (
                      <LessonPackageSelection
                        devPort={devPort}
                        clubId={clubId}
                        selectedPackages={packageRef}
                        setSelectedPackages={setPackageRef}
                        tableType={priceType}
                        multiple_packages={false}
                        setNoReferenceYet={setNoReferenceYet}
                      />
                    )}
                  </Grid2>
                  <Grid2 xs={2}>
                    <Tooltip title="Use the same package reference for packages that can be applied to the same lesson type. For instance, you can have 2 packages 'Academy - 10 lessons' and 'Academy - 5 lessons'. Both should share the same reference so that they can be used for the same lesson type">
                      <IconButton>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
            <Grid2 container xs={12} spacing={2}>
              <Grid2 xs={8}>
                <TextField
                  id="add-package-number"
                  label={"Number of " + packageContent + " in the package"}
                  data-testid="add-package-number"
                  style={{ width: "100%" }}
                  value={numberLesson}
                  disabled={unlimitedLesson}
                  onChange={(input) => {
                    const intLesson = parseInt(input.target.value);
                    if (!Number.isNaN(intLesson)) {
                      setNumberLesson(intLesson);
                    } else {
                      setNumberLesson("");
                    }
                  }}
                />
              </Grid2>
              <Grid2 xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={unlimitedLesson}
                        onChange={() => setUnlimitedLesson(!unlimitedLesson)}
                      />
                    }
                    label="Unlimited"
                  />
                </FormGroup>
              </Grid2>
            </Grid2>
            <Grid2 container xs={12} spacing={2}>
              <Grid2 xs={6}>
                <TextField
                  id="add-package-pricing"
                  label="Package Price"
                  data-testid="add-package-pricing"
                  style={{ width: "100%" }}
                  value={lessonPrice}
                  onChange={(input) => {
                    setLessonPrice(input.target.value);
                  }}
                />
              </Grid2>
              <Grid2 xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="pricing-currency-package">
                    Currency
                  </InputLabel>
                  <Select
                    labelId="select-currency-package"
                    data-testid="select-currency-package"
                    value={currency}
                    label="Currency"
                    onChange={(event) => setCurrency(event.target.value)}
                  >
                    <MenuItem value={"usd"}>USD</MenuItem>
                    <MenuItem value={"cad"}>CAD</MenuItem>
                    <MenuItem value={"eur"}>EUR</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
            <Button
              variant="contained"
              data-testid="register-pricing-package"
              onClick={() => {
                //setLessonDelete("with " + lesson.pro + " at " + lesson.time);
                //setLessonToDelete(lesson.id);
                createPricing();
                console.log("HERE");
              }}
              color="success"
              fullWidth={true}
            >
              <RequestQuoteIcon />
              {"Register New Package"}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default LessonAddPackage;
