function processToken(data, loginTech, devPort, setUserName) {
  const fetch_cmd = devPort + loginTech + data;
  console.log("Logging in...");
  fetch(fetch_cmd, {
    credentials: "include",
    mode: "cors",
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
      if (data) {
        setUserName(data);
        //navigate("/selectmembership");
      } else {
        console.log("Invalid UserId");
      }
    });
}

export default processToken;
