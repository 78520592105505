import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

function MembershipCard({ devPort, userName, membershipType, setCurrentMembership }) {
  const [hasManagerId, setHasManagerId] = useState(false);
  useEffect(() => {
    const fetchManagerId = devPort + "system_get_manager/" + userName;
    console.log(fetchManagerId);
    fetch(fetchManagerId)
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setHasManagerId(data && data[0][0] !== null);
      });
  }, [devPort, userName]);
  return (
    <Paper elevation={24}>
      <Box textAlign="center" data-testid="membership-card">
        <Card>
          <CardContent>
            <Stack gap={2}>
              <Typography variant="h5" component="div">
                {membershipType + " Membership"}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                You are currently enrolled as a {membershipType}
              </Typography>
              {membershipType === "Manager" && (
                <Button
                  color="primary"
                  data-testid="switch-membership-to-player"
                  variant="outlined"
                  onClick={() => setCurrentMembership("player")}
                >
                  {" "}
                  Go to Player Mode
                </Button>
              )}
              {hasManagerId && membershipType === "Player" && (
                <Button
                  color="primary"
                  variant="outlined"
                  data-testid="switch-membership-to-manager"
                  onClick={() => setCurrentMembership("manager")}
                >
                  {" "}
                  Go to Manager Mode
                </Button>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Paper>
  );
}

export default MembershipCard;
