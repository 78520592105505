import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout/Layout";

import PlayerList from "../components/PlayerManagement/PlayerList";
import useRefresh from "../components/Refresh/refresh";
import DashboardTabs from "../components/ui/DashboardTabs";
import getProMapping from "../components/Signups/getProMapping";
import PRO_COLORS from "./proColors";

function LessonSignupPage({
  eventId,
  clubId,
  gameDate,
  devPort,
  waiverNotifications,
  managerMode,
}) {
  const [players, setPlayers] = useState([]);
  const [loadingSignups, setLoadingSignups] = useState(true);
  const [proColorMapping, setProColorMapping] = useState();
  console.log("TEEEEEESSST LESSONS " + eventId);
  let navigate = useNavigate();
  useRefresh(clubId, devPort);
  console.log("Lesson Event" + eventId);
  const loadPlayers = useCallback(() => {
    console.log(devPort + "student_roster/" + clubId + "/" + eventId);
    fetch(devPort + "student_roster/" + clubId + "/" + eventId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let formattedData = [];
        let colorIndex = 0;
        let newProColorMapping = {};
        data.map((player) => {
          formattedData.push({
            player_id: player[0],
            first_name: player[1],
            last_name: player[2],
            uspa_rating: player[3],
            club_rating: player[4],
            lesson_id: player[5],
            signup_notes: player[6],
            paid_lesson: player[7],
            coach: player[8],
          });
          if (player[8] && !newProColorMapping[player[8]]) {
            newProColorMapping[player[8]] = PRO_COLORS[colorIndex];
            if (colorIndex === PRO_COLORS.length - 1) {
              colorIndex = 0;
            } else {
              colorIndex += 1;
            }
          }
          return null;
        });
        console.log("OUTPUT DATA");
        console.log(formattedData);
        setProColorMapping(newProColorMapping);
        setPlayers(formattedData);
        setLoadingSignups(false);
      });
  }, [devPort, clubId, eventId]);
  useEffect(() => {
    loadPlayers();
  }, [loadPlayers]);
  function deletePlayer(playerId) {
    fetch(devPort + "delete_lesson_signup/" + eventId + "/" + playerId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        loadPlayers();
      });
  }
  let currentPage = "/lessonsignup";
  const [proMapping, setProMapping] = useState();
  useEffect(() => {
    loadPlayers();
    const player_fetcher = devPort + "player_list/" + clubId + "/True";
    getProMapping(
      devPort,
      player_fetcher,
      setProMapping,
      () => {},
      () => {} //setLoadingClubPros
    );
  }, [devPort, clubId, loadPlayers]);

  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications}>
      <DashboardTabs
        currentPage={currentPage}
        managerMode={managerMode}
        gameMode="lesson"
      />
      <section>
        <PlayerList
          devPort={devPort}
          loadingPage={loadingSignups}
          managerMode={managerMode}
          title={"Signups for Lesson on " + gameDate}
          clubId={clubId}
          gameId={eventId}
          players={players}
          nameEdit={false}
          ratingEdit={false}
          chukkerEdit={true}
          refresh={loadPlayers}
          playerDelete={deletePlayer}
          addPlayer={() => navigate("/addLessonplayer")}
          proColorMapping={proColorMapping}
          gameMode="lesson"
          tableMode="lessons"
          proMapping={proMapping}
        />
      </section>
    </Layout>
  );
}

export default LessonSignupPage;
