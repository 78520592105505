import classes from "./PlayerItem.module.css";
import {
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";

function PlayerItem(props) {
  let teamColor = "#A2D69A"; // '#5D8AA8';
  if (props.team.match("A_")) {
    teamColor = "#5D8AA8"; //'#800020';
  }
  return (
    <Draggable
      draggableId={props.id}
      isDragDisabled={props.managerMode !== -1}
      index={parseInt(props.id)}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-testid={'player-' + props.id}
        >
          <Paper elevation={24}>
            <Card
              variant="outlined"
              sx={{ mx: -3 }}
              style={{ backgroundColor: teamColor }}
            >
              <CardContent>
                <div className={classes.content} width={5}>
                  {" "}
                  {props.firstName}
                </div>
                <div className={classes.content} width={5}>
                  {" "}
                  {props.lastName}
                </div>
                <div className={classes.content} width={5}>
                  {" "}
                  {props.rating}
                </div>
              </CardContent>
            </Card>
          </Paper>
        </div>
      )}
    </Draggable>
  );
}

export default PlayerItem;
