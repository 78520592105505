async function cryptoField(messageToEncrypt) {
  function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
  function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
  }
  async function importPublicKey() {
    const derKeyLine =
      "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0yGzrrWIPi9b/g14ZhzmjXH69gtO8x360ES52qt7w9f+uSk0xoDqSNCM244vqZETqGws9cUqNhZ25YZwAr/LAIzLDP275gSFj7vZxvNTKbxkpP+Zdq0U6giirZUZXDzoH95p2YEbBxC2V9L9MwZ8Sq9n+U6MrqXx1nFKyJUVG0hSOdI1Bxc6bzagvTvzxvtbq5RqrgtN3kSG/O+uSm78h6Ag0XGkxUF75q9V5q9Et5heEYGYSQ3sjRh3PReEtVfg4+4hGO1O5K+REX6e0N8S5aVza69+uf+GQUtzRS7/qEkHQz/TcesGj89MzlzJ4G3y3O9mse5dC+kG09R+dgYlZ430uvOhD+Nf9CJ9Tv6978JRPky41EKSRoz3mf54UXy/tp3b8zHkdkuarpzJbHBWrJt191TSMuqRVIOoMOgIMR08yVkyeLE0C8vciP+KnIZIa7udq6QKM2CawdXp8k8AFV781zz//ioAm0QR/+L61lBKetHt5htSSj7nzVxK90EZxYxznHcf2sNEms4C3Zi8ENqhRN1zvKvVddDcHa78XAMkBrfQSW673r6nh29OPzARUqY0EGS0xOPAqrh81EolbJ4EUIWdvFKGA7fVIv05vLaIOriAKQmBztMbclNca0msVcc2ENvxjV9a5l8E57SDpK4GhGep1PzSuWymOFCFIKUCAwEAAQ==";
    var binaryDerString = window.atob(derKeyLine);
    const processedKey = str2ab(binaryDerString);
    return await window.crypto.subtle.importKey(
      "spki",
      processedKey,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"]
    );
  }
  async function encryptRSA(key, plaintext) {
    let encrypted = await window.crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      key,
      plaintext
    );
    return encrypted;
  }
  try {
    const pub = await importPublicKey();
    const encrypted = await encryptRSA(
      pub,
      new TextEncoder().encode(messageToEncrypt)
    );
    const encryptedBase64 = window.btoa(ab2str(encrypted));
    return encryptedBase64.replace(/(.{64})/g, "$1\n");
  } catch (error) {
    console.log(error);
    return null;
  }
}

export default cryptoField;
