import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

function SettingsIconCB({ setAnchorEl }) {
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  console.log("PRINTING SETTINGS")
  return (
    <IconButton
      size="large"
      aria-label="user settings"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="secondary"
    >
      <SettingsIcon />
    </IconButton>
  );
}
export default SettingsIconCB;
