function getProMapping(
  devPort,
  player_fetcher,
  setMapping,
  setClubPros,
  setLoadingClubPros
) {
  setLoadingClubPros(true);
  console.log(player_fetcher);
  //console.log(devPort + "player_list/" + clubId + "/True");
  fetch(player_fetcher) //devPort + "player_list/" + clubId + "/True")
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
      const newPros = data.map((game) => {
        //TODO update with player ID
        return {
          label: game[0] + " " + game[1],
          value: game[4],
        };
      });
      const newMapping = {};
      data.map((game) => {
        newMapping[game[4]] = game[0] + " " + game[1];
        return null;
      });
      setMapping(newMapping);
      setClubPros(newPros);
      setLoadingClubPros(false);
    });
}

export default getProMapping;
