import { IconButton, Tooltip } from "@mui/material";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
function SignedWaiver({ hasSigned }) {
  console.log("PAYER");
  if (!hasSigned) {
    return (
      <Tooltip title="Waivers missing">
        <IconButton>
          <ContentPasteOffIcon color="secondary" />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Waivers complete">
      <IconButton>
        <AssignmentTurnedInIcon color="success" />
      </IconButton>
    </Tooltip>
  );
}

export default SignedWaiver;
