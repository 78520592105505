import PlayerItem from "./PlayerItem";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
function PlayerList(props) {
  console.log(props.playerList)
  return (
    <Grid2 container spacing={2}>
      {Object.entries(props.playerList).map(([key, players]) => {
        return (
          <Grid2 xs={12} key={key}>
            <PlayerItem
              id={key}
              managerMode={props.managerMode}
              firstName={players[4]}
              lastName={players[5]}
              rating={props.useUSPARating ? players[6] : players[7]}
              chukker={props.chukker}
              team={props.team}
            />
          </Grid2>
        );
      })}
    </Grid2>
  );
}

export default PlayerList;
