function generateManagerMembership(devPort, userName, stripeEmail, stripeName, setCurrentMembership, setUserName) {
  const stripe_data = {
    cb_username: userName,
    stripe_email: stripeEmail,
    stripe_name: stripeName,
  };
  if(!stripeEmail || !stripeName){
    window.alert("Set a valid email and name")
  }
  const fetchStripeCustomer = devPort + "system_add_stripe_customer";
  fetch(fetchStripeCustomer, {
    method: "POST",
    body: JSON.stringify(stripe_data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("MANAGER CREATED")
      console.log(data);
      setUserName(userName);
      setCurrentMembership("manager");
    });
}

export default generateManagerMembership;
