function hasPlayerSignegWaiver(devPort, clubId, playerId, setPlayerHasSignedWaiver) {
  const fetch_has_player_signed_waiver =
    devPort + "has_player_signed_waiver/" + clubId + "/" + playerId;
  console.log(fetch_has_player_signed_waiver);
  fetch(fetch_has_player_signed_waiver)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("Look at this signatures waivers");
      if (data && data[0]) {
        console.log(data[0][0]);
        setPlayerHasSignedWaiver(data[0][0]);
      }
      console.log(data);
    });
}

export default hasPlayerSignegWaiver;
