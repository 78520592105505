import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "../components/layout/Layout";
import React, { useEffect, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import malletPic from "./MainMallet.jpeg";
import singleMalletPic from "./singleMallet.svg";

import { useNavigate, useSearchParams } from "react-router-dom";
import SnackError from "../components/Signups/SnackError";
import SubscriptionTable from "../components/Subscription/SubscriptionTable.tsx";

function ManagerSubscriptionPage({
  devPort,
  userName,
  setUserName,
  setCurrentMembership,
  clubId,
  subscriptionMode,
}) {
  console.log(devPort);
  let navigate = useNavigate();
  const [basePrice, setBasePrice] = useState("");
  const [price, setPrice] = useState("");
  const [promoCode, setPromoCode] = useState();
  const [validPromoCode, setValidPromoCode] = useState();
  const [validMessage, setValidMessage] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [loadingPricing, setLoadingPricing] = useState(true);

  const [searchParams] = useSearchParams();
  const stripeSessionId = searchParams.get("session_id");
  const [snackInvalid, setSnackInvalid] = useState(false);
  const [pricingOption, setPricingOption] = useState([]);
  const [memberOption, setMemberOption] = useState([]);
  const [numberPlayers, setNumberPlayers] = useState();
  const [updateAgreement, setUpdateAgreement] = useState(false);
  useEffect(() => {
    if (stripeSessionId) {
      const stripeFetch =
        devPort + "retrieve-stripe-customer/" + stripeSessionId;
      fetch(stripeFetch)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            console.log("HERE");
            console.log(data);
            console.log("Stripe ID has been set");
            setUserName(data);
            setCurrentMembership("manager");
            navigate("/club");
          } else {
            console.log("Invalid payment method");
            setSnackInvalid(true);
          }
        });
    }
  }, [devPort, stripeSessionId, setUserName, setCurrentMembership, navigate]);

  useEffect(() => {
    fetch(devPort + "base-price")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setPrice(data[0]);
        setBasePrice(data[0]);
        setLoadingPricing(false);
      });
  }, [devPort]);

  useEffect(() => {
    fetch(devPort + "pricing-scale")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("PRICING SCALW");
        console.log(data);
        if (data) {
          const newMemberOption = Object.keys(data).map((member_number) => {
            return {
              label: member_number,
              value: member_number,
            };
          });
          newMemberOption.sort((a, b) => a.label - b.label);
          console.log(newMemberOption);
          setNumberPlayers(newMemberOption[1].label);
          setMemberOption(newMemberOption);
          setPricingOption(data);
        }
      });
  }, [devPort]);

  function createCheckoutSession() {
    setLoadingPricing(true);
    let fetchCheckout =
      subscriptionMode === "modify"
        ? "modify-subscription"
        : "create-checkout-session";
    fetch(devPort + fetchCheckout, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: userName,
        clubId: clubId,
        numberPlayers: numberPlayers,
        promoCode: validPromoCode,
        website: window.location.href.split("#")[0], //"http://localhost:3000"
      }),
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data && data.url) {
          window.location.href = data.url;
        } else {
          navigate("/club");
        }
      });
  }

  function applyPromoCode() {
    setLoadingPricing(true);
    fetch(devPort + "promo-code/" + promoCode)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data === "invalid") {
          setInvalidMessage(true);
        } else {
          setValidPromoCode(promoCode);
          setPromoCode("");
          setPrice(data[0]);
          setValidMessage(true);
        }
        setLoadingPricing(false);
      });
  }

  function updatePlayerMembers(newNumber) {
    console.log("SETTER");
    console.log(newNumber);
    console.log(pricingOption);
    setNumberPlayers(newNumber);
    setUpdateAgreement(false);
    console.log(validPromoCode);
    if (!validPromoCode) {
      setPrice(pricingOption[newNumber]);
    }
  }

  return (
    <Layout managerMode={-2}>
      <Snackbar
        open={validMessage}
        autoHideDuration={3000}
        onClose={() => {
          setValidMessage(false);
        }}
      >
        <Alert severity="success"> Promo Code has been applied!</Alert>
      </Snackbar>
      <SnackError
        alterMessage={invalidMessage}
        setAlertMessage={setInvalidMessage}
        snackMessage="Promo Code is invalid!"
      />
      <SnackError
        alterMessage={snackInvalid}
        setAlertMessage={setSnackInvalid}
        snackMessage="Invalid Payment Method. Please try again"
      />
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <h1> Manager Registration</h1>
            <Paper elevation={24}>
              <Card variant="outlined" sx={{ mx: -3 }}>
                <CardMedia
                  component="img"
                  alt="mallet pic"
                  image={malletPic}
                  height={240}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    align="center"
                  >
                    Manager Membership
                  </Typography>
                  <Box textAlign="center">
                    <img
                      src={singleMalletPic}
                      alt="Single Mallet"
                      //width={353}
                      height={75}
                      sx={{
                        display: { xs: "none", md: "flex" },
                        mr: 1,
                      }}
                    />
                  </Box>
                  {!loadingPricing && memberOption.length && (
                    <div>
                      <Typography
                        gutterBottom
                        variant="h7"
                        component="div"
                        align="center"
                      >
                        {price !== basePrice && (
                          <Typography
                            style={{ textDecoration: "line-through" }}
                          >
                            {" $"}
                            {basePrice}
                          </Typography>
                        )}
                        {"$" + price +
                          (numberPlayers === "0" ? "" : " per club member") +
                          " per month"}
                      </Typography>
                      <Stack spacing={2}>
                        <Typography variant="body2" color="text.secondary">
                          You will be charged at the begining of the month. The
                          price is dependent on the number of club members
                        </Typography>
                        <FormControl fullWidth>
                          <InputLabel id="club-members">
                            Number of club players
                          </InputLabel>
                          <Select
                            labelId="select-number-players"
                            id="select-number-players"
                            value={numberPlayers}
                            label="Number of club player"
                            onChange={(event) =>
                              updatePlayerMembers(event.target.value)
                            }
                          >
                            {memberOption.map((numberMember) => {
                              console.log(numberMember);
                              return (
                                <MenuItem value={numberMember.value}>
                                  {numberMember.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={numberPlayers === "0"}
                                onChange={() => {
                                  if (numberPlayers === "0") {
                                    updatePlayerMembers(memberOption[1].label);
                                  } else {
                                    updatePlayerMembers("0");
                                  }
                                }}
                              />
                            }
                            label="Freeze your membership for the offseason"
                          />
                        </FormGroup>
                        <Stack direction="row" spacing={2}>
                          <TextField
                            id="promo-code"
                            label="Promo Code"
                            variant="outlined"
                            onChange={(event) => {
                              setPromoCode(event.target.value);
                            }}
                          />
                          <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={!promoCode}
                            onClick={applyPromoCode}
                          >
                            Apply
                          </Button>
                        </Stack>
                        <Stack gap={2}>
                          <SubscriptionTable
                            devPort={devPort}
                            clubId={clubId}
                            newNumberPlayer={parseFloat(numberPlayers)}
                            newPricePerPlayer={price}
                            subscriptionMode={subscriptionMode}
                          />
                          <FormGroup>
                            <FormControlLabel
                              required
                              control={
                                <Checkbox
                                  checked={updateAgreement}
                                  onChange={() =>
                                    setUpdateAgreement(!updateAgreement)
                                  }
                                />
                              }
                              label={
                                subscriptionMode === "create"
                                  ? "I read the term and conditions and I agree to be charged the amount mentioned above on a monthly basis"
                                  : "I agree to upgrade my monthly subscription with the package mentioned above"
                              }
                            />
                          </FormGroup>
                          <Button
                            variant="contained"
                            data-testid="register-player"
                            type="submit"
                            color="success"
                            disabled={!updateAgreement}
                            onClick={createCheckoutSession}
                          >
                            <ShoppingCartIcon />
                            Checkout
                          </Button>
                        </Stack>
                      </Stack>
                    </div>
                  )}
                  {loadingPricing && <LinearProgress color="secondary" />}
                </CardContent>
              </Card>
            </Paper>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default ManagerSubscriptionPage;
