import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
/*if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}*/
navigator.serviceWorker.ready.then((registration) => {
  registration.unregister();
});
root.render(
  //<React.StrictMode>
  <GoogleOAuthProvider clientId="724489852378-kq9ra0fsvtlq43k8442oo22dc142npc4.apps.googleusercontent.com">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GoogleOAuthProvider>
  //</React.StrictMode>
);
