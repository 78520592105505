import { Button, Modal } from "@mui/material";
import { Box, Stack } from "@mui/system";

function SavePromptModal({
  navigateModal,
  setNavigateModal,
  navigateModalFunc,
  saveToDb
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={navigateModal}
      onClose={() => {
        setNavigateModal(false);
      }}
      aria-labelledby="Prompt save to db?"
      aria-describedby="Prompt save to db"
      data-testid="prompt-save-modal"
    >
      <Box sx={style}>
        <h1> Do you want to save your chukkerbook modifications?</h1>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="success"
            data-testid="save-modal-yes"
            onClick={() => {
              setNavigateModal(false);
              saveToDb();
              navigateModalFunc();
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="error"
            data-testid="save-modal-no"
            onClick={() => {
              setNavigateModal(false);
              navigateModalFunc();
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="save-modal-cancel"
            onClick={() => {
              setNavigateModal(false);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default SavePromptModal;
