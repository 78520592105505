import { Alert, Snackbar } from "@mui/material";

function SnackError({alterMessage, setAlertMessage, snackMessage}){
    return (
    <Snackbar
        open={alterMessage}
        autoHideDuration={3000}
        onClose={() => {
          setAlertMessage(false);
        }}
      >
        <Alert severity="error"> {snackMessage}</Alert>
      </Snackbar>
    )
}

export default SnackError