function creditsRetriever(
  managerMode,
  devPort,
  clubId,
  gameId,
  pricingType,
  setValidCredits
) {
  if (managerMode !== -1) {
    const packageFetch =
      devPort +
      "get_valid_player_credits_for_lesson/" +
      clubId +
      "/" +
      gameId +
      "/" +
      managerMode +
      "/" +
      pricingType;
    console.log(packageFetch);
    fetch(packageFetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          console.log("LOOK AT THOSE CREDITS!!!");
          console.log(data);
          if (data && data.length !== 0) {
            let newValidCredits = {};
            data.map((individualPrice) => {
              if (individualPrice[2] || !individualPrice[1]) {
                newValidCredits[individualPrice[0]] = individualPrice[2];
              }
              return null;
            });
            console.log(newValidCredits);
            if (Object.keys(newValidCredits).length !== 0) {
              setValidCredits(newValidCredits);
            } else {
              console.log("No credits loaded");
              setValidCredits();
            }
          } else {
            console.log("SETTING TO NULL");
            setValidCredits();
          }
        }
      });
  }
}

export default creditsRetriever;
