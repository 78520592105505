import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import getProMapping from "./getProMapping";

function ProBox({
  devPort,
  clubId,
  setIdPro,
  refresh,
  proRequest,
  boxLabel,
  disabled,
  disabledPros = [],
}) {
  const [clubPros, setClubPros] = useState();
  const [loadingClubPros, setLoadingClubPros] = useState(true);
  const [mapping, setMapping] = useState();
  useEffect(() => {
    const player_fetcher = devPort + "player_list/" + clubId + "/True";
    getProMapping(
      devPort,
      player_fetcher,
      setMapping,
      setClubPros,
      setLoadingClubPros
    );
  }, [refresh, clubId, devPort]);
  useEffect(() => {
    if (proRequest && setIdPro) {
      setIdPro(proRequest);
    }
  }, [proRequest, setIdPro]);
  if (loadingClubPros) {
    return <LinearProgress color="secondary" />;
  }
  return (
    <Autocomplete
      disablePortal
      id="RequestedPro"
      data-testid="available-pros"
      options={clubPros}
      //value={proDropdown || null}
      defaultValue={
        proRequest ? { value: proRequest, label: mapping[proRequest] } : null
      }
      getOptionDisabled={(option) =>
        disabledPros.some((e) => e.value === option.value)
      }
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={{ width: 325 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={boxLabel ? boxLabel : "Request to Play with Pro"}
        />
      )}
      onChange={(event, newValue) => {
        if (newValue) {
          setIdPro(newValue.value);
        } else {
          setIdPro("-1");
        }
        //setProDropdown(newValue.value);
      }}
    />
  );
}

export default ProBox;
