import { Stack } from "@mui/material";
import BannerMessage from "../Signups/PriceBanner";

function CreditsBanner({ validCredits }) {
  return (
    <Stack data-testid="credits-list">
      {Object.keys(validCredits).map((creditRef) => {
        return (
          <Stack
            data-testid={"credits-banner-" + creditRef}
            key={"credits-banner-" + creditRef}
          >
            <BannerMessage
              message={
                "You still have  " +
                (validCredits[creditRef] ? validCredits[creditRef] : "") +
                " credits from your package " +
                creditRef +
                " that you can use here"
              }
              color={"success"}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}

export default CreditsBanner;
