function isSubscriber(devPort, userName, setCurrentMembership) {
  console.log(userName);
  console.log("Processing name");
  console.log(devPort);
  //TODO user Stripe user ID
  //fetch(devPort + "is-subscriber/" + userName)
  fetch(devPort + "system_get_manager/" + userName)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data && data[0][0]) {
        //.data && data.data.length != 0) {
        setCurrentMembership("manager");
      } else {
        const fetchUserId = devPort + "system_get_player/" + userName;
        fetch(fetchUserId)
          .then((idResponse) => {
            return idResponse.json();
          })
          .then((idData) => {
            if (idData && idData[0][0]) {
              console.log("Player Account Found");
              setCurrentMembership("player");
            } else {
              console.log("NO ACCOUNT FOUND");
            }
          });
      }
    });
}

export default isSubscriber;
