import { Stack, TableCell, TableRow } from "@mui/material";
import { Fragment } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import poloMalletLogo from "./PoloTest.png";
function SpreadsheetRow({
  team,
  teamColor,
  playerName,
  managerMode,
  playerId,
  chukkerSchedule,
  playerIndex,
}) {
  return (
    /*<div
      {...providedTeamDrag.draggableProps}
      {...providedTeamDrag.dragHandleProps}
      ref={innerRef}
    >*/

    <Fragment>
      <Draggable
        key={team + "____" + playerName}
        draggableId={team + "____" + playerName}
        isDragDisabled={managerMode !== -1}
        index={playerIndex}
      >
        {(providedTeamDrag) => (
          <TableRow
            {...providedTeamDrag.draggableProps}
            {...providedTeamDrag.dragHandleProps}
            ref={providedTeamDrag.innerRef}
            key={playerName}
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
          >
            <TableCell
              style={{
                left: 0,
                background: teamColor,
                zIndex: 800,
                position: "sticky",
              }}
              component="th"
              scope="row"
              width="300%"
              rowSpan={2}
            >
              <Stack direction="row">
                <DragHandleIcon />
                {playerId}
              </Stack>
            </TableCell>
          </TableRow>
        )}
      </Draggable>
      <Droppable
        droppableId={team + "____" + playerName}
        type={"SPREADSHEET_X_" + playerName}
        direction="horizontal"
      >
        {(provided) => (
          <TableRow ref={provided.innerRef} {...provided.droppableProps}>
            {chukkerSchedule.map((chukker, index) => {
              return (
                <TableCell
                  align="center"
                  width="100%"
                  size="medium"
                  key={team + "____" + playerName + "____" + index}
                >
                  {" "}
                  {chukker === "x" ? (
                    <Draggable
                      draggableId={team + "____" + playerName + "____" + index}
                      isDragDisabled={managerMode !== -1}
                      index={index}
                    >
                      {(providedChukker) => (
                        <img
                          ref={providedChukker.innerRef}
                          {...providedChukker.draggableProps}
                          {...providedChukker.dragHandleProps}
                          data-testid={
                            "table-" + team + "-" + playerName + "-" + index
                          }
                          src={poloMalletLogo}
                          alt="Mallet x"
                          width={30}
                          height={30}
                          sx={{
                            display: {
                              xs: "none",
                              md: "flex",
                            },
                            mr: 1,
                          }}
                        />
                      )}
                    </Draggable>
                  ) : (
                    <Draggable
                      draggableId={team + "____" + playerName + "____" + index}
                      isDragDisabled={true}
                      index={index}
                    >
                      {(providedChukker) => (
                        <div
                          ref={providedChukker.innerRef}
                          {...providedChukker.draggableProps}
                          {...providedChukker.dragHandleProps}
                        ></div>
                      )}
                    </Draggable>
                  )}{" "}
                </TableCell>
              );
            })}
          </TableRow>
        )}
      </Droppable>
    </Fragment>

    //</div>
  );
}

export default SpreadsheetRow;
