function getStripeConnectAccessList(devPort, clubId, setStripeConnectUsers) {
  const fetch_club_access = devPort + "player_stripe_connect/" + clubId;
  console.log(fetch_club_access);
  fetch(fetch_club_access)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("Stripe Access");
      console.log(data);
      const newData = data.map((info) => {
        return {
          label: info[0] + " " + info[1],
          value: info[2],
          firstName: info[0],
          lastName: info[1],
          clubId: clubId,
        };
      });
      if (newData) {
        setStripeConnectUsers(newData);
      } else {
        setStripeConnectUsers([]);
      }
    });
}

export default getStripeConnectAccessList;
