import { Typography } from "@mui/material";

import StripeConnectCardWrapper from "./StripeConnectCardWrapper";

function StripeConnectCardDisabled() {
  return (
    <StripeConnectCardWrapper>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        You have not been marked as a coach by any manager. If you are a coach
        and would like to start receiving payment, ask your club manager to add
        you to the list of coaches who can receive payment for their lessons.
      </Typography>
    </StripeConnectCardWrapper>
  );
}

export default StripeConnectCardDisabled;
