import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import { Droppable } from "react-beautiful-dnd";
import SpreadsheetRow from "./SpreadsheetRow";
//import Asset1 from "./polox.png";
//import Asset2 from "./spreadsheet.png";
//import Asset1 from '../../../public/PoloTest.png'
//import Spreadsheet_wo_vector from "./Spreadsheet_wo_vector.png";

function MatchupTable({ playerList, chukkerNumber, managerMode, idMapping }) {
  function printHeader(teamColor) {
    const header = [];
    for (let i = 0; i < chukkerNumber; i++) {
      header.push(
        <TableCell align="center" style={{ background: teamColor }}>
          {" "}
          {"Chukker " + (i + 1)}{" "}
        </TableCell>
      );
    }
    console.log(header);
    return header;
  }
  return (
    <Stack spacing={2}>
      {Object.keys(playerList).map((team) => {
        //const teamColor = team === "A" ? "primary" : "success";
        let teamColor = "#A2D69A"; // '#5D8AA8';
        if (team.match("A")) {
          teamColor = "#5D8AA8"; //'#800020';
        }
        return (
          <Paper elevation={24} key={team}>
            <Droppable
              droppableId={team}
              type="SPREADSHEET_TEAM_SWAP"
              direction="vertical"
            >
              {(providedTeam) => (
                <TableContainer
                  component={Paper}
                  data-testid={"spreadsheet-matchup-" + team}
                  ref={providedTeam.innerRef}
                  {...providedTeam.droppableProps}
                >
                  <Table stickyHeader arial-label="matchup table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            left: 0,
                            background: teamColor,
                            zIndex: 800,
                            position: "sticky",
                          }}
                          width="200px"
                          rowSpan={2}
                        >
                          {"Team " + team}
                        </TableCell>
                      </TableRow>
                      <TableRow>{printHeader(teamColor)}</TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(playerList[team]).map(
                        (playerName, playerIndex) => {
                          return (
                            <SpreadsheetRow
                              //innerRef={providedTeamDrag.innerRef}
                              //providedTeamDrag={providedTeamDrag}
                              team={team}
                              teamColor={teamColor}
                              playerName={playerName}
                              managerMode={managerMode}
                              playerId={idMapping[playerName]}
                              chukkerSchedule={playerList[team][playerName]}
                              playerIndex={playerIndex}
                            />
                          );
                        }
                      )}
                      {providedTeam.placeholder}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Droppable>
          </Paper>
          //)}
          //</Droppable>
        );
      })}
    </Stack>
  );
}

export default MatchupTable;
