import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SavePromptModal from "./SavePromptModal";

function DashboardTabs({
  currentPage,
  managerMode,
  toReload,
  saveToDb,
  gameMode,
}) {
  let navigate = useNavigate();
  const [navigateModal, setNavigateModal] = useState(false);
  const [goToPage, setGoToPage] = useState("");
  function goToNewPage() {
    navigate(goToPage);
  }
  function triggerModal(page) {
    if (!currentPage.match("matchup") || !toReload) {
      navigate(page);
    } else {
      setGoToPage(page);
      setNavigateModal(true);
    }
  }
  function TabContent({ managerMode }) {
    if (managerMode === -1) {
      return (
        <Tabs
          value={currentPage}
          onChange={(event, newValue) => triggerModal(newValue)}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            data-testid="signup-page-manager"
            value={gameMode === "game" ? "/signup" : "/lessonsignup"}
            label="Signups"
          />
          <Tab
            data-testid="addplayer-page-manager"
            value={gameMode === "game" ? "/addplayer" : "/addlessonplayer"}
            label="Add Player"
          />
          {gameMode === "game" && (
            <Tab
              data-testid="matchup-page-manager"
              value="/matchup"
              label="Matchup"
            />
          )}
        </Tabs>
      );
    }
    return (
      <Tabs
        value={currentPage}
        onChange={(event, newValue) => triggerModal(newValue)}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          data-testid="signup-page-player"
          value={gameMode === "game" ? "/playersignup" : "/lessonsignup"}
          label="Signups"
        />
        <Tab
          data-testid="register-page-player"
          value={gameMode === "game" ? "/playerregister" : "/addlessonplayer"}
          label="Register"
        />
        {gameMode === "game" && (
          <Tab
            data-testid="matchup-page-player"
            value="/playermatchup"
            label="Matchup"
          />
        )}
      </Tabs>
    );
  }
  console.log(currentPage);
  console.log(managerMode);
  return (
    <Box sx={{ width: "100%" }}>
      <SavePromptModal
        navigateModal={navigateModal}
        setNavigateModal={setNavigateModal}
        navigateModalFunc={goToNewPage}
        saveToDb={saveToDb}
      />
      <TabContent managerMode={managerMode} />
    </Box>
  );
}

export default DashboardTabs;
