import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ProRequest from "./ProRequest";
import PaidLease from "./PaidLease";
import Edit from "./EditButton";
import Delete from "./DeleteButton";
import ChukkerbookAccount from "./ChukkerbbokAccount";
import SignedWaiver from "./SignedWaiver";
import ProPlayer from "./ProPlayer";

function PlayerName({ playerName, playerId, isPro, proColorMapping }) {
  return (
    <Typography
      color={
        isPro
          ? proColorMapping && proColorMapping[playerId]
            ? proColorMapping[playerId][0]
            : "orange"
          : "regular"
      }
    >
      {playerName}
    </Typography>
  );
}

function get_columns(page, managerMode) {
  if (page === "games") {
    if (managerMode === -1) {
      return [
        "Player Name",
        "Chukkers",
        "Horse Lease Chukkers",
        "Pro Request",
        "Note",
        "Paid Lease",
        "Signed Waiver",
        "Edit",
        "Delete",
      ];
    } else {
      return ["Player Name", "Chukkers", "Pro Request"];
    }
  }
  if (page === "lessons") {
    if (managerMode === -1) {
      return [
        "Player Name",
        "Coach",
        "Note",
        "Paid Lesson",
        "Signed Waiver",
        "Delete",
      ];
    } else {
      return ["Player Name", "Coach"];
    }
  }
  if (page === "members") {
    if (managerMode === -1) {
      return [
        "Player Name",
        "ChukkerBook Account",
        "Pro Player",
        "USPA Rating",
        "Club Rating",
        "Signed Waiver",
        "Edit",
        "Delete",
      ];
    } else {
      return ["Player Name", "ChukkerBook Account", "Pro Player"];
    }
  }
  return [];
}

function PlayerSignupTable({
  signups,
  searchField,
  proColorMapping,
  page,
  managerMode,
  setPlayerId,
  setPlayerFirstName,
  setPlayerLastName,
  setDeleteModal,
  setplayerModalEdit,
  setPlayerRating,
  setPlayerClubRating,
  setPlayerUSPARating,
  setPlayerIsPro,
  setChukkerNumber,
  setProRequest,
  setPlayerHasSignedWaiver,
  setPlayerModal,
  proMapping,
}) {
  let teamColor = "#5D8AA8";
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const columns = get_columns(page, managerMode);
  console.log(signups);
  function get_rows(page, row) {
    let player_mapping = {};
    if (page === "games") {
      player_mapping = {
        "Player Name": (
          <PlayerName
            playerName={row["first_name"] + " " + row["last_name"]}
            playerId={row["player_id"]}
            isPro={row["is_club_pro"]}
            proColorMapping={proColorMapping}
          />
        ),
        Chukkers: row["count_chukkers_requested"],
        "Horse Lease Chukkers": row["count_chukkers_leased"]
          ? row["count_chukkers_leased"]
          : "-",
        "Pro Request": (
          <ProRequest
            requestedPro={row["selected_pro_id"]}
            proColorMapping={proColorMapping}
            proMapping={proMapping}
          />
        ),
        Note: row["signup_notes"] ? row["signup_notes"] : "-",
        "Paid Lease": <PaidLease hasPaid={row["paid_lease"]} />,
        "Signed Waiver": <SignedWaiver hasSigned={row["up_to_date_waiver"]} />,
        Edit: (
          <Edit
            playerInfo={row}
            setplayerModalEdit={setplayerModalEdit}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setPlayerRating={setPlayerRating}
            setPlayerClubRating={setPlayerClubRating}
            setPlayerUSPARating={setPlayerUSPARating}
            setPlayerIsPro={setPlayerIsPro}
            setChukkerNumber={setChukkerNumber}
            setProRequest={setProRequest}
            setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
            setPlayerModal={setPlayerModal}
          />
        ),
        Delete: (
          <Delete
            playerInfo={row}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setDeleteModal={setDeleteModal}
          />
        ),
      };
    }
    if (page === "lessons") {
      player_mapping = {
        "Player Name": (
          <PlayerName playerName={row["first_name"] + " " + row["last_name"]} />
        ),
        Coach: (
          <ProRequest
            requestedPro={row["coach"]}
            proColorMapping={proColorMapping}
            proMapping={proMapping}
          />
        ),
        Note: row["signup_notes"] ? row["signup_notes"] : "-",
        "Paid Lesson": <PaidLease hasPaid={row["paid_lesson"]} />,
        "Signed Waiver": <SignedWaiver hasSigned={row["up_to_date_waiver"]} />,
        Edit: (
          <Edit
            playerInfo={row}
            setplayerModalEdit={setplayerModalEdit}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setPlayerRating={setPlayerRating}
            setPlayerClubRating={setPlayerClubRating}
            setPlayerUSPARating={setPlayerUSPARating}
            setPlayerIsPro={setPlayerIsPro}
            setChukkerNumber={setChukkerNumber}
            setProRequest={setProRequest}
            setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
            setPlayerModal={setPlayerModal}
          />
        ),
        Delete: (
          <Delete
            playerInfo={row}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setDeleteModal={setDeleteModal}
          />
        ),
      };
    }
    if (page === "members") {
      player_mapping = {
        "Player Name": (
          <PlayerName playerName={row["first_name"] + " " + row["last_name"]} />
        ),
        "ChukkerBook Account": (
          <ChukkerbookAccount hasAccount={row["has_chukkerbook_account"]} />
        ),
        "Pro Player": <ProPlayer isPro={row["is_club_pro"]} />,
        "USPA Rating": row["uspa_rating"],
        "Club Rating": row["club_rating"],
        "Signed Waiver": <SignedWaiver hasSigned={row["up_to_date_waiver"]} />,
        Edit: (
          <Edit
            playerInfo={row}
            setplayerModalEdit={setplayerModalEdit}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setPlayerRating={setPlayerRating}
            setPlayerClubRating={setPlayerClubRating}
            setPlayerUSPARating={setPlayerUSPARating}
            setPlayerIsPro={setPlayerIsPro}
            setChukkerNumber={setChukkerNumber}
            setProRequest={setProRequest}
            setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
            setPlayerModal={setPlayerModal}
          />
        ),
        Delete: (
          <Delete
            playerInfo={row}
            setPlayerId={setPlayerId}
            setPlayerFirstName={setPlayerFirstName}
            setPlayerLastName={setPlayerLastName}
            setDeleteModal={setDeleteModal}
          />
        ),
      };
    }
    console.log(player_mapping);
    return player_mapping;
  }
  return (
    <Paper elevation={24}>
      <TableContainer component={Paper} data-testid={"table-signup"}>
        <Table stickyHeader arial-label="signup table">
          <TableHead>
            <TableRow style={{ position: "sticky", zIndex: 800 }}>
              {columns.map((column, index) => {
                console.log(column);
                return (
                  <TableCell
                    align="center"
                    style={{
                      left: index === 0 ? 0 : "",
                      background: teamColor,
                      color: "white",
                      zIndex: index === 0 ? 800 : "",
                      position: index === 0 ? "sticky" : "",
                    }}
                    width="200px"
                    //rowSpan={2}
                  >
                    {column}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {signups.map((row, signupIndex) => {
              const player_mapping = get_rows(page, row);
              if (
                (row["first_name"] + " " + row["last_name"])
                  .toLowerCase()
                  .match(searchField)
              ) {
                return (
                  <StyledTableRow key={signupIndex}>
                    {columns.map((column, index) => {
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{
                            left: index === 0 ? 0 : "",
                            zIndex: index === 0 ? 600 : "",
                            position: index === 0 ? "sticky" : "",
                            backgroundColor: index === 0 ? teamColor : "",
                            color: index === 0 ? "white" : "",
                          }}
                        >
                          {player_mapping[column]}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default PlayerSignupTable;
