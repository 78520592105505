import {
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import LessonPackageSelection from "./LessonPackageSelection";

function DefaultMembership({ devPort, clubId }) {
  const [selectedPackages, setSelectedPackages] = useState();

  useEffect(() => {
    console.log(devPort + "get_default_membership_club/" + clubId);
    fetch(devPort + "get_default_membership_club/" + clubId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data[0]) {
          setSelectedPackages(data[0][0]);
        }
      });
  }, [devPort, clubId]);

  function saveDefaultMembership(is_delete_package) {
    const horseChukkerFetch = devPort + "set_default_membership_club";
    const horseChukkerSave = {
      selected_package: is_delete_package ? null : selectedPackages,
      club_id: clubId,
    };
    console.log(horseChukkerSave);
    if (!selectedPackages) {
      window.alert("Enter a valid package reference from the dropdown menu");
    } else {
      fetch(horseChukkerFetch, {
        method: "POST",
        body: JSON.stringify(horseChukkerSave),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
        });
    }
  }
  return (
    <Paper elevation={24}>
      <Card variant="elevation">
        <CardContent>
          <Stack spacing={2}>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              data-testid="horse-chukker-disclaimer"
            >
              Specify the list of Memberships you want to apply to all games by
              default. For instance, if you have an "Annual Club Membership"
              that applies to all games, you could include it here. If you have
              a "July 2024 Summer Academy", it should probably not be part of
              this list and be specified during game creation
            </Typography>
            <LessonPackageSelection
              devPort={devPort}
              clubId={clubId}
              selectedPackages={selectedPackages}
              setSelectedPackages={setSelectedPackages}
              tableType="Membership"
              multiple_packages={false}
            />
            {selectedPackages && (
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  data-testid="register-default-package"
                  onClick={() => {
                    saveDefaultMembership(false);
                    console.log("HERE");
                  }}
                  color="success"
                  fullWidth={true}
                  style={{
                    height: "100%",
                  }}
                >
                  <SaveIcon />
                  {"Apply"}
                </Button>
                <Button
                  variant="contained"
                  data-testid="delete-default_package"
                  onClick={() => {
                    setSelectedPackages();
                    saveDefaultMembership(true);
                    console.log("HERE");
                  }}
                  color="secondary"
                  fullWidth={true}
                  style={{
                    height: "100%",
                  }}
                >
                  <DeleteIcon />
                  {"Delete"}
                </Button>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default DefaultMembership;
