function generateMembership(devPort, userName, managerMode) {
  var fetchUserName =
    devPort + "system_add_membership/" + userName + "/None/True";
  if (managerMode !== -1) {
    fetchUserName =
      devPort + "system_add_membership/" + userName + "/True/None";
  }
  console.log(fetchUserName);
  fetch(fetchUserName)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("User ID has been set");
    });
}

export default generateMembership;
