function currencyFormatter(value, currency) {
  const value_decimal = value.toFixed(2);
  if (currency === "usd") {
    return "$" + value_decimal;
  }
  if (currency === "eur") {
    return value_decimal + "€";
  }
  if (currency === "cad") {
    return "CA$" + value_decimal;
  }
  return currency + " " + value_decimal;
}

export default currencyFormatter;
