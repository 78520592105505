import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import getProMapping from "./getProMapping";

function PlayerBox({
  devPort,
  clubId,
  gameId,
  setIdPlayer,
  refresh,
  managerMode,
  playerFixedId,
  mode,
}) {
  const [clubPlayers, setClubPlayers] = useState();
  const [loadingClubPlayers, setLoadingClubPlayers] = useState(true);
  const [loadingSignups, setLoadingSignups] = useState(true);
  const [mapping, setMapping] = useState();
  const [players, setPlayers] = useState();
  //const [playerDropdown, setPlayerDropdown] = useState();
  const loadPlayers = useCallback(() => {
    setLoadingSignups(true);
    //TODO HANDLE FOR OTHER SIDE
    //setIdPro(-1);
    let roster = "player_roster";
    if (mode === "lesson") {
      roster = "student_roster";
    }
    console.log(devPort + roster + "/" + clubId + "/" + gameId);
    fetch(devPort + roster + "/" + clubId + "/" + gameId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("IMNP DATA");
        console.log(data);
        let newPLayers = [];
        if (data) {
          newPLayers = data.map((player) => {
            return player[0]; //1] + " " + player[2];
          });
        }
        setPlayers(newPLayers);
        setLoadingSignups(false);
      });
  }, [devPort, clubId, gameId, mode]);

  useEffect(() => {
    loadPlayers();
  }, [loadPlayers, refresh]);

  useEffect(() => {
    const player_fetcher = devPort + "player_list/" + clubId + "/False";
    getProMapping(
      devPort,
      player_fetcher,
      setMapping,
      setClubPlayers,
      setLoadingClubPlayers
    );
  }, [clubId, devPort]);
  useEffect(() => {
    if (playerFixedId && players) {
      if (players.includes(playerFixedId)) {
        setIdPlayer(null);
      } else {
        setIdPlayer(playerFixedId);
      }
    }
  }, [playerFixedId, setIdPlayer, players]);
  if (loadingClubPlayers || loadingSignups) {
    return <LinearProgress color="secondary" />;
  }
  return (
    <Autocomplete
      disablePortal
      id="PlayerName"
      data-testid="player-name"
      options={clubPlayers}
      getOptionDisabled={(option) => players.includes(option.value)}
      isOptionEqualToValue={(option, value) => option.value === value}
      //value={playerDropdown || null}
      defaultValue={
        playerFixedId
          ? { value: playerFixedId, label: mapping[playerFixedId] }
          : null
      }
      disabled={managerMode !== -1}
      sx={{ width: 325 }}
      renderInput={(params) => <TextField {...params} label="Player Name" />}
      onChange={(event, newValue) => {
        if (newValue) {
          setIdPlayer(newValue.value);
        } else {
          setIdPlayer(null);
        }
        //setPlayerDropdown(newValue.value);
      }}
      /*onChange={(event, newValue) => {
        console.log(newValue.value);
        setIdPlayer(idMapping[newValue.value]);
        setPlayerDropdown(newValue.value);
      }}*/
    />
  );
}

export default PlayerBox;
