import { Stack } from "@mui/material";
import SingleUser from "./SingleUser";

function RenderUserList({
  devPort,
  headerLine,
  userList,
  managerMode,
  listMode,
  refresher,
}) {
  return (
    <Stack>
      <h5>{headerLine}</h5>
      {userList.map((waiter) => {
        return (
          <SingleUser
            key={waiter.value}
            devPort={devPort}
            userData={waiter}
            managerMode={managerMode}
            listMode={listMode}
            refresher={refresher}
          />
        );
      })}
    </Stack>
  );
}

export default RenderUserList;
