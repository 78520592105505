import {
  Button,
  Modal,
  TextField,
  Box,
  Stack,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import ProBox from "../Signups/ProBox";
import moment from "moment";

function RegistrationModal({
  managerMode,
  devPort,
  editMode,
  clubId,
  gameId,
  playerId,
  playerModal,
  setPlayerModal,
  baseEdit,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  rating,
  setRating,
  playerClubRating,
  setPlayerClubRating,
  playerUSPARating,
  setPlayerUSPARating,
  playerHasSignedWaiver,
  setPlayerHasSignedWaiver,
  chukkerNumber,
  setChukkerNumber,
  refresh,
  proRequest,
  isPro,
  setIsPro,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  console.log("PRO TEST");
  console.log(isPro);
  const [idPro, setIdPro] = useState("-1");
  const playerRatings = [];
  const [updateWaiverSignedDate, setUpdateWaiverSignedDate] = useState(false);
  for (var i = -2; i <= 10; i++) {
    playerRatings.push({ label: i, value: i });
  }
  const numberChukkers = [];
  for (i = 1; i <= 10; i++) {
    numberChukkers.push({ label: i, value: i });
  }
  function updateFirstName(event) {
    console.log(event.target.value);
    setFirstName(event.target.value);
  }
  function updateLastName(event) {
    console.log(event.target.value);
    setLastName(event.target.value);
  }

  function updateChukkerNumber(event) {
    console.log(event.target.value);
    setChukkerNumber(event.target.value);
  }
  function resetModal() {
    setFirstName("");
    setLastName("");
    setChukkerNumber(2);
    setRating(-2);
  }
  function ChukkerDisplay() {
    if (!baseEdit) {
      return (
        <div>
          <div>Number of Chukkers</div>
          <Box sx={{ width: 350 }}>
            <Slider
              aria-label="Number of Chukkers"
              min={1}
              max={10}
              value={chukkerNumber}
              step={1}
              onChange={updateChukkerNumber}
              valueLabelDisplay="auto"
              marks={numberChukkers}
            />
          </Box>
        </div>
      );
    }
    return;
  }
  function registerPlayer() {
    if (!firstName) {
      window.alert("Enter First Name");
    } else {
      if (!lastName) {
        window.alert("Enter Last name");
      } else {
        const fetch_add_player = devPort + "add_club_player";
        const player_details = {
          club_id: clubId,
          player_id: playerId,
          first_name: firstName,
          last_name: lastName,
          uspa_rating: playerUSPARating,
          club_rating: playerClubRating,
          is_club_pro: isPro,
          has_signed_waiver: playerHasSignedWaiver,
        };
        console.log(fetch_add_player);
        fetch(fetch_add_player, {
          mode: "cors",
          method: "POST",
          body: JSON.stringify(player_details),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log("AAAAAAA")
            console.log(data)
            if (data) {
              console.log("BBBBBBB")
              changeWaiverSignedDate(data, playerHasSignedWaiver);
            }
            setPlayerModal(false);
            resetModal();
            console.log("Refreshing");
            refresh();
          });
      }
    }
  }
  function updatePlayer(api_fetch) {
    console.log(api_fetch);
    fetch(api_fetch).then((data) => {
      setPlayerModal(false);
      resetModal();
      refresh();
    });
  }
  function updatePlayer_top() {
    if (editMode) {
      if (baseEdit) {
        if (updateWaiverSignedDate) {
          changeWaiverSignedDate(playerId, playerHasSignedWaiver);
        }
        updatePlayer(
          devPort +
            "edit_player/" +
            playerId +
            "/" +
            firstName +
            "/" +
            lastName +
            "/" +
            playerUSPARating +
            "/" +
            playerClubRating +
            "/" +
            clubId +
            "/" +
            isPro
        );
      } else {
        updatePlayer(
          devPort +
            "edit_signup/" +
            clubId +
            "/" +
            gameId +
            "/" +
            playerId +
            "/" +
            chukkerNumber +
            "/" +
            idPro
        );
      }
    } else {
      if (baseEdit) {
        registerPlayer();
      }
    }
  }
  function changeWaiverSignedDate(playerId, playerHasSignedWaiver) {
    const fetch_player_waiver_signed_date =
      devPort + "change_player_signed_date";
    const change_waiver_siggned_date = {
      club_id: clubId,
      player_id: playerId,
      renewal_date: playerHasSignedWaiver
        ? moment().utc().format("YYYY-MM-DD")
        : "1970-01-01",
    };
    console.log(change_waiver_siggned_date);
    fetch(fetch_player_waiver_signed_date, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(change_waiver_siggned_date),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Waiver Renewal");
        if (data && data["message"].match("Successful")) {
          console.log(data["message"]);
        }
      });
  }
  return (
    <Modal
      open={playerModal}
      onClose={() => {
        setPlayerModal(false);
      }}
      aria-labelledby="Player Edit"
      aria-describedby="Edit Player"
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <TextField
            disabled={!baseEdit}
            id="player-first-name"
            data-testid="player-first-name"
            label="First Name"
            defaultValue={firstName}
            variant="filled"
            onChange={updateFirstName}
          />
          <TextField
            disabled={!baseEdit}
            id="player-last-name"
            label="Last Name"
            defaultValue={lastName}
            variant="filled"
            onChange={updateLastName}
          />
          {managerMode === -1 && baseEdit && (
            <div>
              <div>USPA Rating</div>
              <Box sx={{ width: 350 }}>
                <Slider
                  aria-label="USPA Rating"
                  data-testid="uspa-rating"
                  disabled={!baseEdit}
                  value={playerUSPARating}
                  step={1}
                  min={-2}
                  max={10}
                  onChange={(event, newValue) => {
                    setPlayerUSPARating(newValue);
                  }}
                  valueLabelDisplay="auto"
                  marks={playerRatings}
                />
              </Box>
              <div>Club Rating</div>
              <Box sx={{ width: 350 }}>
                <Slider
                  aria-label="Club Rating"
                  data-testid="club-rating"
                  disabled={!baseEdit}
                  value={playerClubRating}
                  step={1}
                  min={-2}
                  max={10}
                  onChange={(event, newValue) => {
                    setPlayerClubRating(newValue);
                  }}
                  valueLabelDisplay="auto"
                  marks={playerRatings}
                />
              </Box>
            </div>
          )}
          <ChukkerDisplay />
          {baseEdit && managerMode === -1 && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="modal-is-pro"
                    checked={isPro}
                    onChange={() => setIsPro(!isPro)}
                  />
                }
                label="Is Pro Player"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="modal-has-signed-waiver"
                    checked={playerHasSignedWaiver}
                    onChange={() => {
                      setUpdateWaiverSignedDate(true);
                      setPlayerHasSignedWaiver(!playerHasSignedWaiver);
                    }}
                  />
                }
                label="Player Has Signed The Waiver"
              />
            </FormGroup>
          )}
          {!baseEdit && (
            <ProBox
              devPort={devPort}
              clubId={clubId}
              setIdPro={setIdPro}
              refresh={false}
              proRequest={proRequest}
            />
          )}
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="success"
              onClick={updatePlayer_top}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setPlayerModal(false);
                resetModal();
              }}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default RegistrationModal;
