import { Box, Card, CardContent, Paper, Typography } from "@mui/material";

import StripeLogo from "./stripe.png";

function StripeConnectCardWrapper({ children }) {
  return (
    <Paper elevation={24}>
      <Box textAlign="center" data-testid="stripe-connect-card">
        <Card>
          <CardContent>
            <Typography variant="h5" component="div">
              {"Stripe Connect Account"}
            </Typography>
            <img
              src={StripeLogo}
              alt="Stripe Logo"
              width={209.98}
              height={100}
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            />
            {children}
          </CardContent>
        </Card>
      </Box>
    </Paper>
  );
}

export default StripeConnectCardWrapper;
