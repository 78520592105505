import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const pricingColumns = ["Pricing Name", "Price"];
const pricingRows = ["price"];
const packageColumns = [
  "Package Name",
  "Package Reference",
  "Number of Credits",
  "Price",
];
const packageColumnsPurchase = [
    "Package Name",
    "Number of Credits",
    "Price",
    "Checkout",
  ];
const packageRows = ["reference", "credit", "price"];
const packageRowsPurchase = ["credit", "price", "checkout"];

const pricingDetails = {
  pricing: { columns: pricingColumns, rows: pricingRows },
  package: { columns: packageColumns, rows: packageRows },
  purchasePackage: { columns: packageColumnsPurchase, rows: packageRowsPurchase },
};

function PricingTable({ lessonPricing, tableType }) {
  let teamColor = "#5D8AA8"; // "#A2D69A"; // '#5D8AA8';
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  if(!lessonPricing || lessonPricing.length===0){
    return
  }
  return (
    <Paper elevation={24}>
      <TableContainer
        component={Paper}
        data-testid={"table-pricing-" + tableType}
        sx={{ maxHeight: 440 }}
      >
        <Table stickyHeader arial-label="pricing table">
          <TableHead>
            <TableRow style={{ position: "sticky", zIndex: 800 }}>
              {pricingDetails[tableType]["columns"].map((pricing, index) => {
                return (
                  <TableCell
                    align={index === 0 ? "left" : "center"}
                    style={{
                      left: 0,
                      background: index === 0 ? teamColor : "",
                      zIndex: index === 0 ? 800 : "",
                      position: index === 0 ? "sticky" : "",
                    }}
                    width="200px"
                    rowSpan={2}
                  >
                    {pricing}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {lessonPricing.map((row) => {
              return (
                <StyledTableRow
                  key={row.name}
                  data-testid={"table-row-pricing-" + row.name}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      left: 0,
                      zIndex: 600,
                      position: "sticky",
                    }}
                  >
                    {row.name}
                  </TableCell>
                  {pricingDetails[tableType]["rows"].map((pricingRow) => {
                    return (
                      <TableCell align="center">{row[pricingRow]}</TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default PricingTable;
