import { Typography } from "@mui/material";
import { Parallax } from "react-parallax";
import MainHorses from "../../pages/MainHorses.jpeg";

import "./pages.css";
import { ParallaxBanner } from "react-scroll-parallax";

function BackgroundParallax() {
  const advancedLib = true;
  if (!advancedLib) {
    return (
      <Parallax className="image" bgImage={MainHorses} strength={700}>
        <Typography className="content" variant="h3" color="primary">
          Spend less time planning and more time playing.
        </Typography>
      </Parallax>
    );
  } else {
    return (
      <ParallaxBanner
        layers={[{ image: MainHorses, speed: -20 }]}
        className="image"
      >
        <Typography className="content" variant="h3" color="primary">
          Spend less time planning and more time playing.
        </Typography>
      </ParallaxBanner>
    );
  }
}

export default BackgroundParallax;
