import { useCallback, useEffect, useState } from "react";
import PricingTable from "./PricingTable";
import { Fab, Stack } from "@mui/material";
import currencyFormatter from "./currencyFormatter";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BannerMessage from "../Signups/PriceBanner";

function PurchasePackage({
  devPort,
  clubId,
  gameId,
  idPlayer,
  managerMode,
  signupNotes,
  lessonPackagePurchase,
  setLessonPackagePurchase,
  packageType,
  chukkerRequested,
  horseLeaseChukkers,
  leasePrice,
  idPro,
}) {
  const [checkoutParams, setCheckoutParams] = useState();
  
  const createCheckoutLesson = useCallback(
    (package_stripe_ref, package_reference) => {
      console.log(idPlayer);
      console.log(managerMode);
      if (!idPlayer) {
        window.alert("Select a player from the dropdown menu");
      } else {
        let paymentInfo = {
          playerId: idPlayer,
          uid: managerMode,
          clubId: clubId,
          gameId: gameId,
          signupNotes: signupNotes,
          lesson_price_id: package_stripe_ref,
          packageReference: package_reference,
          package_type: packageType,
          website: window.location.href.split("#")[0], //"http://localhost:3000"
        };
        console.log(packageType);
        if (packageType === "membership") {
          paymentInfo["number_chukkers"] = chukkerRequested;
          console.log(horseLeaseChukkers);
          if (horseLeaseChukkers) {
            console.log("THERE");
            paymentInfo["number_leased_chukkers"] = horseLeaseChukkers;
            paymentInfo["lease_price_id"] = leasePrice;
          }
          if(idPro){
            paymentInfo["pro_id"] = idPro
          }
        }
        console.log(paymentInfo);
        fetch(devPort + "create-checkout-lesson-package", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentInfo),
        })
          .then((response) => {
            console.log(response);
            return response.json();
          })
          .then((data) => {
            console.log(data);
            window.location.href = data.url;
          });
      }
    },
    [
      chukkerRequested,
      clubId,
      devPort,
      gameId,
      idPlayer,
      idPro,
      leasePrice,
      managerMode,
      packageType,
      signupNotes,
      horseLeaseChukkers,
    ]
  );

  useEffect(() => {
    if (checkoutParams) {
      createCheckoutLesson(checkoutParams[0], checkoutParams[1]);
    }
  }, [checkoutParams, createCheckoutLesson]);

  useEffect(() => {
    console.log("LEASER");
    console.log(horseLeaseChukkers);
  }, [horseLeaseChukkers]);
  useEffect(() => {
    const packageFetch =
      devPort +
      "get_registered_package_for_lesson/" +
      clubId +
      "/" +
      gameId +
      "/" +
      packageType +
      "_pricing";
    console.log(packageFetch);
    fetch(packageFetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          console.log("LOOK AT THOS PACKAGES!!!");
          console.log(data);
          if (data) {
            const newLessonPackagePurchase = data.map((individualPrice) => {
              return {
                name: individualPrice[0],
                price: currencyFormatter(
                  parseFloat(individualPrice[1]) / 100.0,
                  individualPrice[4]
                ),
                stripe_id: individualPrice[3],
                currency: individualPrice[4],
                credit: individualPrice[2] ? individualPrice[2] : "-",
                checkout: (
                  <Fab
                    color="primary"
                    size="small"
                    aria-label="purchase"
                    onClick={
                      () =>
                        setCheckoutParams([
                          individualPrice[3],
                          individualPrice[5],
                        ])
                      /*createCheckoutLesson(
                        individualPrice[3],
                        individualPrice[5]
                      )*/
                    }
                  >
                    <ShoppingCartIcon />
                  </Fab>
                ),
              };
            });
            setLessonPackagePurchase(newLessonPackagePurchase);
          } else {
            setLessonPackagePurchase([]);
          }
        }
      });
  }, [devPort, clubId, gameId, packageType, setLessonPackagePurchase]);
  if (
    !lessonPackagePurchase ||
    lessonPackagePurchase.length === 0 ||
    managerMode === -1
  ) {
    return;
  }
  return (
    <Stack spacing={2}>
      <BannerMessage
        message={
          "You can buy one of the following packages to register for this " + (packageType==="membership" ? "game" : "lesson")
        }
        color={"success"}
      />
      <PricingTable
        lessonPricing={lessonPackagePurchase}
        tableType="purchasePackage"
      />
    </Stack>
  );
}

export default PurchasePackage;
