import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useRefresh(id, devPort) {
  let navigate = useNavigate();
  useEffect(() => {
    if(!devPort){
      navigate('/')
    }
    fetch(devPort + "get_cookies", {
      credentials: "include",
      mode: "cors",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          console.log("Valid COOKIE");
          if (!id || id === -1) {
            console.log("Redirecting");
            navigate("/");
            //Need to redirect to club selection navigate("/");
          }
        } else {
          console.log("Invalid cookie");
          navigate("/");
        }
      });
  }, [devPort, id, navigate]);
}

export default useRefresh;
