import RenderUserList from "./RenderUserList";

function UserList({ devPort, userList, managerMode, headerLine, listMode, refresher }) {
  console.log("WAITLIST COMPO");
  console.log(userList);
  if (!userList || userList.length === 0) {
    return;
  }
  /*if (managerMode === -1) {
      return userList.map((club) => {
        return (
          <ClubWaiterCard devPort={devPort} clubData={club} key={club.value} />
        );
      });
    }*/
  return (
    <RenderUserList
      devPort={devPort}
      userList={userList}
      managerMode={managerMode}
      headerLine={headerLine}
      listMode={listMode}
      refresher={refresher}
    />
  );
}

export default UserList;
