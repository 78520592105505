function getWaiverLinks(devPort, clubId, setWaiverLinks) {
  const fetch_waiver_links = devPort + "club_waiver_links/" + clubId;
  console.log(fetch_waiver_links);
  fetch(fetch_waiver_links)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("Waiver Links");
      if (data && data[0]) {
        console.log(data);
        setWaiverLinks(data);
      }
      console.log(data);
    });
}

export default getWaiverLinks;